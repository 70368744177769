import { useEffect, useRef, useState } from "react";
import { Button, Container, Row, Col, Form, Spinner, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Photo from "../_components/signup/Photo";
import API from "../_helpers/api";
import Actions from "../_redux/actions";
import { useDebounceEffect } from '../_helpers/useDebounceEffect'
import { BsPersonCircle } from 'react-icons/bs'
import { canvasPreview } from '../_helpers/canvasPreview'

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export default function Settings(props) {


    const user = useSelector(state => state.user)
    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [prefix, setPrefix] = useState('')
    const [suffix, setSuffix] = useState('')
    const [pronouns, setPronouns] = useState('')

    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(1)
    const [show, setShow] = useState(false)

    const [oldPW, setOldPW] = useState('')
    const [newPW, setNewPw] = useState('')
    const [confirmPW, setConfirmPW] = useState('')
    const [pwErr, setPwErr] = useState('')
    const [pwLoading, setPwLoading] = useState(false)


    const dispatch = useDispatch()


    const [loading, setLoading] = useState(false)
    const [imgLoad, setImgLoad] = useState(false)
    const [err, setErr] = useState('')



    useEffect(() => {
        setFName(user?.fName)
        setLName(user?.lName)
        setPrefix(user?.prefix)
        setSuffix(user?.suffix)
        setPronouns(user?.pronouns)
        document.title = 'JustTheTap - Settings'

    }, [user])

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    const _logout = () => {
        localStorage.removeItem('jwt')
        window.location.reload()
    }

    const _updateUser = async (e) => {
        e.preventDefault()
        setLoading(true)

        if (fName === '' || lName === '') {
            setErr('First and Last Name Required')
            setLoading(false)

            return
        }
        const res = await API.updateUser({
            fName,
            lName,
            prefix,
            suffix,
            pronouns
        })
        setLoading(false)

        dispatch(Actions.setUser(res.user))
        dispatch(Actions.setToast('✅ Name Information Updated', 'Your cards will now reflect this information.'))

    }

    const _submitPassChange = async (e) => {
        e.preventDefault()
        setPwErr('')
        setPwLoading(true)
        if (oldPW === newPW) {
            setPwErr('Old and New Passwords Cannot Be the Same')
            clearPW()
            return
        }
        if (newPW.length < 8) {
            setPwErr('New password must be at least 8 characters long')
            clearPW()
            return
        }

        if (newPW !== confirmPW) {
            setPwErr('Passwords do not match')
            clearPW()
            return
        }

        const res = await API.changePW(oldPW, newPW)

        if (res.errMsg) {
            setPwErr(res.errMsg)
        } else {
            dispatch(Actions.setToast('Your Password Has Successfully Been Updated', 'Next time you log in, use your new password'))
            clearPW()
        }
        setPwLoading(false)


    }


    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }


    const clearPW = () => {
        setNewPw('')
        setOldPW('')
        setConfirmPW('')
        setPwLoading(false)

    }


    function centerAspectCrop(
        mediaWidth,
        mediaHeight,
        aspect
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 400,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    const _upload = async () => {
        setImgLoad(true)
        const base64Image = previewCanvasRef.current.toDataURL('image/jpeg');

        const res = await fetch(base64Image)
        const data = await API.presignProfile()

        await API.uploadImage(data.url, await res.blob())
    }



    const _submit = async (e) => {
        e.preventDefault()
        _upload()
        const tempUser = JSON.parse(JSON.stringify(user))
        tempUser.hasProfilePic = true
        const res = await API.updateUser(tempUser)
        dispatch(Actions.setUser(res.user))

        setTimeout(() => {
            setImgLoad(false)
            dispatch(Actions.setToast('✅ Profile Pic Updated', 'It might take up to 30 seconds to see these changes reflected'))
        }, 2300)

    }



    return (
        <Container fluid className='p-3 pb-5'>
            <Row>
                <Col >
                    <h1 className='text-start'>Settings</h1>
                    <p className='text-start'><span className='text-primary'>Logged In As</span> <em>{user?.email}</em></p>
                </Col>
                <Col className='text-end'>
                    <Button variant='link' onClick={_logout}>Logout</Button>
                </Col>
            </Row>

            <Row>
                <Col md={6} className='p-2 text-start'>
                    <div className='p-3 border bg-white shadow'>
                        <h2>Personal Info</h2>


                        <Form className='mt-4' onSubmit={_updateUser}>
                            <Form.Group className="mb-3 text-start">
                                <Form.Label className='text-start'>First Name</Form.Label>
                                <Form.Control required type="text" value={fName} onChange={e => setFName(e.target.value)} placeholder="First Name" />
                            </Form.Group>
                            <Form.Group className="mb-3 text-start">
                                <Form.Label className='text-start'>Last Name</Form.Label>
                                <Form.Control required type="text" value={lName} onChange={e => setLName(e.target.value)} placeholder="Last Name" />
                            </Form.Group>
                            <Form.Group className="mb-3 text-start">
                                <Form.Label className='text-start'>Prefix <i>(Optional)</i></Form.Label>
                                <Form.Control type="text" value={prefix} onChange={e => setPrefix(e.target.value)} placeholder="Sir. Dr." />
                            </Form.Group>

                            <Form.Group className="mb-3 text-start">
                                <Form.Label className='text-start'>Suffix <i>(Optional)</i></Form.Label>
                                <Form.Control type="text" value={suffix} onChange={e => setSuffix(e.target.value)} placeholder="JR. III." />
                            </Form.Group>


                            <Form.Group className="mb-3 text-start">
                                <Form.Label className='text-start'>Pronouns <i>(Optional)</i></Form.Label>
                                <Form.Control type="text" value={pronouns} onChange={e => setPronouns(e.target.value)} placeholder="Pronouns" />
                            </Form.Group>


                            <div className="text-center">

                                <Button type='submit' variant='primary px-5 m-3' disabled={loading}>Update</Button>
                                {loading && <div className='text-center mt-2'>
                                    <Spinner animation="border" />
                                </div>}

                                {err !== '' && <Alert variant='danger p-2 mt-2'>Error: {err}</Alert>}
                            </div>
                        </Form>

                    </div>
                </Col>

                <Col md={6} className='p-2 text-center'>
                    <div className='p-3 border bg-white shadow h-100'>
                        <h2 className='text-start'>Personal Info</h2>

                        <Row>
                            <Col md={4} className='mx-auto'>
                                {imgSrc === '' && !user?.hasProfilePic && <BsPersonCircle size='6rem' className='text-primary' />}
                                {imgSrc === '' && user?.hasProfilePic && <img className='img-fluid' alt='profile' src={`https://justthetap.s3.amazonaws.com/profile-pic/${user?._id}?${(new Date()).getTime()}`} />}
                                {imgSrc !== '' && (

                                    <ReactCrop
                                        crop={crop}
                                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                                        onComplete={(c) => setCompletedCrop(c)}
                                        aspect={aspect}
                                    >
                                        <img
                                            ref={imgRef}
                                            alt="Crop me"
                                            src={imgSrc}
                                            className='img-fluid'
                                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                            onLoad={onImageLoad}
                                        />
                                    </ReactCrop>

                                )}


                            </Col>
                        </Row>
                        <h2>Upload Your Headshot</h2>
                        <p>This image will be shown when you share your contact card.</p>
                        <p>NOTE: You can crop this image</p>


                        <Row>
                            <Col md={7} className='mx-auto text-center'>
                                <Form onSubmit={_submit}>
                                    <Form.Group className="mb-3 text-start">
                                        <Form.Control placeholder='Pet Image' size='lg' required type="file" onChange={onSelectFile} />
                                    </Form.Group>
                                    < hr />
                                    {imgLoad && <div className='text-center my-2'><Spinner animation='border' /></div>}
                                    <Button variant='primary w-100' type='submit' disabled={imgLoad || imgSrc === ''}>Upload Image</Button>
                                    <br />

                                    <Row style={{ display: 'none' }}>
                                        <Col md={6} className='mx-auto'>
                                            {(completedCrop) && (
                                                <canvas
                                                    ref={previewCanvasRef}
                                                    className='img-fluid'

                                                    style={{
                                                        objectFit: 'contain',
                                                        width: completedCrop.width,
                                                        height: completedCrop.height,
                                                    }}
                                                />)}
                                        </Col>

                                    </Row>
                                </Form>
                            </Col>
                        </Row>



                    </div>
                </Col>
            </Row>

            <Row className='pb-5 mb-5'>
                <Col md={6} className='p-2 text-start mx-auto'>
                    <div className='p-3 border bg-white shadow'>
                        <h2 className='text-start'>Change Password</h2>

                        <form onSubmit={_submitPassChange}>


                            <Form.Group >
                                <Form.Label>Old Password</Form.Label>
                                <Form.Control type='password' required placeholder='Old Password' value={oldPW} onChange={e => setOldPW(e.target.value)} />

                            </Form.Group>
                            <hr className='m-3' />
                            <Form.Group >
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type='password' required placeholder='New Password' value={newPW} onChange={e => setNewPw(e.target.value)} />
                            </Form.Group>
                            <Form.Group className='mt-3' >
                                <Form.Label>Confirm New Password</Form.Label>
                                <Form.Control type='password' required placeholder='Confirm Password' value={confirmPW} onChange={e => setConfirmPW(e.target.value)} />

                            </Form.Group>
                            {pwErr !== '' && <Alert variant='danger m-2'>{pwErr}</Alert>}
                            <div className='text-center'>
                                <Button variant='primary text-white m-4' type='submit' disabled={oldPW === '' || newPW === '' || confirmPW === '' || pwLoading}>Change Password</Button>
                                <br />
                                {pwLoading && <div className='text-center my-2'><Spinner animation='border' /></div>}


                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}