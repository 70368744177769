import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API
const HEADERS = { 'Content-Type': 'application/json' }
// const BASE_URL = 'https://7raqk5gmwtivwpk7d3y256fspy0rflmd.lambda-url.us-east-1.on.aws'

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: false,
})


axiosInstance.interceptors.request.use(
    async (req) => {
        // Add configurations here
        req.headers["Authorization"] = 'Bearer: ' + localStorage.getItem('jwt')
        return req;
    },
    (err) => {
        return Promise.reject(err);
    }
);


const API = {

    async wakeUp() {
        return axiosInstance.get(`${BASE_URL}/`, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },

    async signup(params) {
        return axiosInstance.post(`${BASE_URL}/user`, params, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => ({ isErr: true, errMsg: "Wait 2 minutes, you've tried too many times" }))
    },

    async replaceCard(cardID, addy, msg) {
        return axiosInstance.post(`${BASE_URL}/card/replace`, { cardID, addy, msg }, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },

    async getContacts() {
        return axiosInstance.get(`${BASE_URL}/user/contacts`, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },

    async getAnalytic(cardID, timeFrame) {
        return axiosInstance.post(`${BASE_URL}/analytic/get`, { cardID, timeFrame }, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },

    async uploadImage(url, file) {
        return axios.put(url, file, { headers: { 'Cache-Control': 'no-cache' } })
            .then(result => {
                console.log(result)
            })
            .catch(error => {
                console.log("ERROR " + JSON.stringify(error));
            })
    },


    async presignFile(fileName) {
        return axiosInstance.post(`${BASE_URL}/card/presign-file`, { fileName }, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },

    async addContact(cardID) {
        return axiosInstance.post(`${BASE_URL}/user/add-contact`, { cardID }, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },

    async presignProfile() {
        return axiosInstance.post(`${BASE_URL}/user/presign-profile`, {}, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },


    async claimCard(nanoid, pass) {
        return axiosInstance.post(`${BASE_URL}/card/claim`, { nanoid, pass }, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => ({ isErr: true, errMsg: 'You tried too many times, please try again in 10 minutes' }))
    },

    async updateCard(card) {
        return axiosInstance.put(`${BASE_URL}/card`, { card }, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },


    async changePW(oldPW, newPW) {
        return axiosInstance.post(`${BASE_URL}/user/change-password`, { oldPW, newPW }, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },


    async forgotPassword(email) {
        return axiosInstance.post(`${BASE_URL}/user/forgot-password`, { email }, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => ({ isErr: true, errMsg: "Wait 10 minutes, you've tried too many times" }))
    },


    async getUser() {
        return axiosInstance.get(`${BASE_URL}/user`, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },

    async getCard(id) {
        return axiosInstance.get(`${BASE_URL}/card/${id}`, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },

    async logAnalytic(cardId, userId, type, fieldId) {
        return axiosInstance.post(`${BASE_URL}/analytic/`, { cardId, userId, type, fieldId }, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },


    async updateUser(user) {
        return axiosInstance.put(`${BASE_URL}/user`, { user }, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },

    async checkResetToken(token) {
        return axiosInstance.post(`${BASE_URL}/user/validate-reset`, { token }, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },

    async resetPassword(token, password) {
        return axiosInstance.post(`${BASE_URL}/user/reset-password`, { token, password }, { headers: HEADERS })
            .then(res => res.data)
            .catch(err => err)
    },

    async login(email, password) {
        return axiosInstance.post(`${BASE_URL}/user/login`, { email, password }, { headers: HEADERS })
            .then(res => {
                if (!res.data.errMsg) {
                    localStorage.setItem('jwt', res.data.jwt)
                    window.location.reload()
                }
                return res.data
            })
            .catch(err => ({ isErr: true, errMsg: "Wait 2 minutes, you've tried too many times" }))
    }

}

export default API