import { Container, Row, Col, Form, Button, Spinner, Alert, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserAlt } from 'react-icons/fa'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import API from "../../_helpers/api";
import { useDispatch } from "react-redux";
import Actions from '../../_redux/actions'
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

export default function PersonalInfo(props) {

    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [email, setEmail] = useState('')
    const [pwd, setPwd] = useState('')
    const [captcha, setCaptcha] = useState('')

    const [confirmPwd, setConfirmPwd] = useState('')
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState('')
    const [showPw, setShowPw] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()


    useEffect(() => {
        loadCaptchaEnginge(6);

    }, [])

    const _submitPersonalInfo = async (e) => {
        e.preventDefault()
        setErr('')
        setLoading(true)

        if (pwd !== confirmPwd) {
            setErr('Passwords Did Not Match')
            setLoading(false)
        } else if (!/(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/.test(pwd)) {
            setErr('Passwords must be at least 8 characters long, contain 1 uppercase character, 1 lowercase character, 1 number, and 1 special character')
            setLoading(false)
        } else if (validateCaptcha(captcha) !== true) {
            setCaptcha('')
            setErr('Captcha is Incorrect')
            setLoading(false)
        } else {
            const res = await API.signup({
                email: email.trim().toLocaleLowerCase(),
                password: pwd
            })
            setLoading(false)
            if (res.errMsg) {
                setErr(res.errMsg)
            } else {
                localStorage.setItem('jwt', res.jwt)
                dispatch(Actions.setUser(res.user))
                dispatch(Actions.nextSignup())
            }
        }

    }
    return (
        <Container className='p-4 pt-0 text-center'>

            <Row className='border shadow-lg rounded mx-auto animate__animated animate__fadeInRight '>
                <Col md={6} className='bg-primary rounded p-3 ' style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                    <div className='d-flex flex-column align-items-center justify-content-center h-100 p-3'>
                        <div>
                            <h1 className='text-white mt-2'>Say Goodbye to Paper Business Cards. </h1>
                            <p className='text-white'>Enter your information to get started!</p>
                        </div>
                    </div>
                </Col>
                <Col md={6} className='p-4' >

                    <Row>
                        <Col md={7} className='mx-auto'>
                            <Form className='mt-4' onSubmit={_submitPersonalInfo}>
                                <Form.Group className="mb-3 text-start">
                                    <Form.Label className='text-start'>Email</Form.Label>
                                    <Form.Control required type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                                </Form.Group>
                                <Form.Group className="mb-3 text-start" >
                                    <Form.Label className='text-start'>Password</Form.Label>

                                    <InputGroup className="mb-3">
                                        <Form.Control required type={!showPw ? "password" : 'text'} placeholder="Password" value={pwd} onChange={e => setPwd(e.target.value)} />
                                        <InputGroup.Text>{showPw ? <AiFillEye className='pointer' onClick={() => setShowPw(!showPw)} /> : <AiFillEyeInvisible className='pointer' onClick={() => setShowPw(!showPw)} />}</InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="mb-3 text-start" >
                                    <Form.Label className='text-start'>Confirm Password</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control required type={!showConfirm ? "password" : 'text'} placeholder="Password" value={confirmPwd} onChange={e => setConfirmPwd(e.target.value)} />
                                        <InputGroup.Text>{showConfirm ? <AiFillEye className='pointer' onClick={() => setShowConfirm(!showConfirm)} /> : <AiFillEyeInvisible className='pointer' onClick={() => setShowConfirm(!showConfirm)} />}</InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>


                                <Form.Group className="mb-3 text-start">
                                    <Form.Label className='text-start'>Captcha</Form.Label>
                                    <LoadCanvasTemplateNoReload />

                                    <Form.Control required type="text" className='mt-1' value={captcha} onChange={e => setCaptcha(e.target.value)} placeholder="Enter Captcha" />
                                </Form.Group>

                                <Button type='submit' variant='primary px-5' disabled={loading}>Next</Button>
                                {loading && <div className='text-center mt-2'>
                                    <Spinner animation="border" />
                                </div>}

                                {err !== '' && <Alert variant='danger p-2 mt-2'>Error: {err}</Alert>}

                                <hr className="mx-5" />
                                <Button variant="link" onClick={() => navigate('/login')}>Back To Login</Button>
                            </Form>
                        </Col>
                    </Row>
                </Col>

            </Row>


        </Container>
    )
}