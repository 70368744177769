import { useEffect, useState } from "react";
import { Form, Button, Spinner, Row, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ImArrowDown, ImArrowUp } from 'react-icons/im'
import { BsFillTrashFill, BsFillEnvelopeFill, BsFillCalendarFill, BsFacebook, BsFillFileEarmarkTextFill, BsGithub, BsLinkedin, BsFillTelephoneFill, BsTwitter, BsYoutube, BsGlobe } from 'react-icons/bs'
import { MdLocationCity } from 'react-icons/md'
import { AiFillInstagram, AiOutlineLink } from 'react-icons/ai'
import { FaStickyNote, FaTiktok } from 'react-icons/fa'
import 'react-phone-number-input/style.css'

import PhoneInput from 'react-phone-number-input'

import API from "../_helpers/api";
import Actions from "../_redux/actions";


export default function Field({ index, total, handleDrag, handleDrop, field, newOne, close, swap }) {

    const user = useSelector(state => state.user)
    const [type, setType] = useState(field ? field?.type : 'LINK')
    const [displayText, setDisplayText] = useState(field ? field.displayText : '')
    const [link, setLink] = useState(field ? field.text : '')
    const selectedCard = useSelector(state => state.selectedCard)

    const [isNew, setIsNew] = useState(true)
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState()
    const [showDelete, setShowDelete] = useState(false);


    const dispatch = useDispatch()

    useEffect(() => {
    }, [])

    const _submit = async (e) => {
        e.preventDefault()
        setLoading(true)

        if (type === 'FILE') {

            if (file) {
                if (file?.size > 2097152) {
                    dispatch(Actions.setToast('🚨 MAX FILE SIZE IS 2MB', 'Please select a smaller file to continue'))
                    setLoading(false)
                    return
                }

                try {
                    const urlRes = await API.presignFile(file?.name?.replaceAll(' ', '_'))

                    await API.uploadImage(urlRes.url, file)
                } catch (e) {
                    console.log(e)
                    dispatch(Actions.setToast('🚨 An Unknown Error Occurred', 'Please try again later'))
                    setLoading(false)
                    return
                }
            }
        }
        if (newOne) {
            const tempCard = JSON.parse(JSON.stringify(selectedCard))
            tempCard.fields.push({
                order: tempCard.fields?.length + 1,
                text: link,
                displayText: displayText,
                type
            })

            const res = await API.updateCard(tempCard)
            dispatch(Actions.setCards(res.cards))
            dispatch(Actions.setSelectedCard(res.cards?.find(card => card._id === selectedCard?._id)))


            dispatch(Actions.setToast('✅ Field Added', 'Your update is live'))
            close()
        } else {
            const tempCard = JSON.parse(JSON.stringify(selectedCard))

            const tempField = JSON.parse(JSON.stringify(field))

            tempField.text = link
            tempField.displayText = displayText
            tempField.type = type

            const foundIndex = tempCard?.fields.findIndex(obj => obj._id === tempField._id)
            tempCard.fields[foundIndex] = tempField


            const res = await API.updateCard(tempCard)
            dispatch(Actions.setCards(res.cards))
            dispatch(Actions.setSelectedCard(res.cards?.find(card => card._id === selectedCard?._id)))


            dispatch(Actions.setToast('✅ Field Added', 'Your update is live'))
            setLoading(false)

        }

    }






    const _removeField = async () => {

        const tempCard = JSON.parse(JSON.stringify(selectedCard))

        tempCard.fields = tempCard.fields?.filter(f => f._id !== field._id)

        const res = await API.updateCard(tempCard)
        dispatch(Actions.setCards(res.cards))
        dispatch(Actions.setSelectedCard(res.cards?.find(card => card._id === selectedCard?._id)))


        dispatch(Actions.setToast('Field Removed', 'Your update is live'))
        setLoading(false)
        setShowDelete(false)
    }

    const _fileChange = (file) => {

        setFile(file)
        setLink(user._id + '/' + file.name?.replaceAll(' ', '_'))
    }
    return (
        <div
            className={` p-3 border shadow m-3 rounded ${newOne ? 'border-primary' : ''}`}
            id={field?._id}
        >
            {!newOne && <div className='text-end p-1'
            >

                <BsFillTrashFill className='pointer text-danger me-2' size='1.2rem' title='Remove Field' onClick={() => setShowDelete(true)} />

                {index !== total - 1 && <ImArrowDown className='pointer text-primary me-2' size='1.2rem' title='Move Down' onClick={() => swap(index, 'DOWN')} />}

                {index !== 0 && <ImArrowUp className='pointer text-primary' size='1.2rem' title='Move Up' onClick={() => swap(index, 'UP')} />}
            </div>}

            <Form onSubmit={_submit}>

                <Row>
                    {type && <Col xs={2}>
                        <div className={`bg-${selectedCard.color} cardBtn d-flex  flex-column justify-content-center align-items-center`}>

                            {type === 'ADDRESS' && <MdLocationCity className="text-white" size='1.1rem' />}
                            {type === 'CALENDLY' && <BsFillCalendarFill className="text-white" size='1.1rem' />}
                            {type === 'EMAIL' && <BsFillEnvelopeFill className="text-white" size='1.1rem' />}
                            {type === 'FACEBOOK' && <BsFacebook className="text-white" size='1.1rem' />}
                            {type === 'FILE' && <BsFillFileEarmarkTextFill className="text-white" size='1.1rem' />}
                            {type === 'GITHUB' && <BsGithub className="text-white" size='1.1rem' />}
                            {type === 'INSTAGRAM' && <AiFillInstagram className="text-white" size='1.1rem' />}
                            {type === 'LINK' && <AiOutlineLink className="text-white" size='1.1rem' />}
                            {type === 'LINKED_IN' && <BsLinkedin className="text-white" size='1.1rem' />}
                            {type === 'NOTE' && <FaStickyNote className="text-white" size='1.1rem' />}
                            {type === 'PHONE' && <BsFillTelephoneFill className="text-white" size='1.1rem' />}
                            {type === 'TIKTOK' && <FaTiktok className="text-white" size='1.1rem' />}
                            {type === 'TWITTER' && <BsTwitter className="text-white" size='1.1rem' />}
                            {type === 'YOUTUBE' && <BsYoutube className="text-white" size='1.1rem' />}
                            {type === 'WEBSITE' && <BsGlobe className="text-white" size='1.1rem' />}
                        </div>
                    </Col>}
                    <Col xs={10}>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='weight-bold'>{newOne && 'New '}Field Type</Form.Label>
                            <Form.Select value={type} required onChange={e => setType(e.target.value)} >
                                <option value='ADDRESS'>Address</option>
                                <option value='CALENDLY'>Calendly</option>
                                <option value='EMAIL'>Email</option>
                                <option value='FACEBOOK'>Facebook</option>
                                <option value='FILE'>File</option>
                                <option value='GITHUB'>GitHub</option>
                                <option value='INSTAGRAM'>Instagram</option>
                                <option value='LINK'>Link</option>
                                <option value='LINKED_IN'>Linked In</option>
                                <option value='NOTE'>Note</option>
                                <option value='PHONE'>Phone Number</option>
                                <option value='TIKTOK'>TikTok</option>
                                <option value='TWITTER'>Twitter</option>
                                <option value='YOUTUBE'>YouTube</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                {["CALENDLY", "FACEBOOK", "GITHUB", "INSTAGRAM", "LINK", "LINKED_IN", "TIKTOK", "TWITTER", "YOUTUBE", "WEBSITE"].includes(type) && <>
                    <Form.Group className="mb-3" >
                        <Form.Label>Display Text</Form.Label>
                        <Form.Control required type="text" value={displayText} onChange={e => setDisplayText(e.target.value)} placeholder="What is Shown" />
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Label>Link</Form.Label>
                        <Form.Control required type="url" value={link} onChange={e => setLink(e.target.value)} placeholder="https://" />
                    </Form.Group>
                </>}

                {["ADDRESS", "NOTE"].includes(type) && <>
                    <Form.Group className="mb-3" >
                        <Form.Label>Display Text</Form.Label>
                        <Form.Control as='textarea' rows={3} required type="text" value={displayText} onChange={e => setDisplayText(e.target.value)} placeholder={type === 'ADDRESS' ? 'Enter Your Address' : 'Enter Your Note'} />
                    </Form.Group>
                </>}

                {type === 'EMAIL' && <>
                    <Form.Group className="mb-3" >
                        <Form.Label>Email</Form.Label>
                        <Form.Control required type="email" value={displayText} onChange={e => setDisplayText(e.target.value)} placeholder='example@email.com' />
                    </Form.Group>
                </>}

                {type === 'PHONE' && <>
                    <Form.Group className="mb-3" >
                        <Form.Label>Phone</Form.Label>
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={displayText}
                            onChange={e => setDisplayText(e)}
                            defaultCountry={'US'}
                        />

                    </Form.Group>
                </>}

                {type === 'FILE' && <>
                    <Form.Group className="mb-3" >
                        <Form.Label>File</Form.Label>
                        <Form.Control required type="file" onChange={e => _fileChange(e.target.files[0])} placeholder='Select File' />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Display Text</Form.Label>
                        <Form.Control required type="text" value={displayText} onChange={e => setDisplayText(e.target.value)} placeholder="What is Shown" />
                    </Form.Group>
                </>}

                <div className='text-center'>
                    {newOne && <Button type='submit' disabled={loading}>Add {loading && <Spinner animation='border' />}</Button>}
                    {!newOne && <Button type='submit' variant='link noDecoration' disabled={loading}>Update {loading && <Spinner size='sm' animation='border' />}</Button>}

                    <br />
                    {newOne && <Button variant='link noDecoration mt-4' onClick={close}> Cancel</Button>}

                </div>


                <Modal
                    show={showDelete}
                    onHide={() => setShowDelete(false)}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Are You Sure You?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Removing this field cannot be undone.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={() => setShowDelete(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={_removeField}>Delete</Button>
                    </Modal.Footer>
                </Modal>

            </Form>
        </div>
    );
}

