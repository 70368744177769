import { Row, Col, Nav, Modal, Button } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { AiOutlineContacts, AiFillIdcard, AiOutlineSetting, AiOutlineTeam, AiOutlineClockCircle, AiOutlineNotification, AiOutlineSearch } from 'react-icons/ai'
import { FaBell, FaBellSlash } from 'react-icons/fa'
import {TbCards} from 'react-icons/tb'
import {BsFillImageFill} from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'
import API from '../_helpers/api'
import Actions from '../_redux/actions'
import GetStarted from './getstarted'
import uuid from 'react-uuid';

export default function SideBarNav(props) {

    const user = useSelector(state => state.user)

    const [getStarted, setGetStarted] = useState(false)
    const [deviceChecked, setDeviceChecked] = useState(false)
    const [deviceId, setDeviceId] = useState('')
    const [showNotify, setShowNotify] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {

    }, [])


    return (
        <div className="flex-column vh-100 shadow   border-end text-start pb-4  d-flex justify-content-between flex-column align-middle bg-primary ">
            <div>

                <div className='text-center bg-white'>
                    <NavLink to='/cards'><img src={require('../assets/logo.png')} alt='JustTheTap Logo' className='img-fluid w-75 mx-auto mt-2 ' /></NavLink>
                    <hr className='mx-2 text-white' />
                </div>

                <Nav className='p-3 px-5 ps-1 flex-column'  >

                    <NavLink to='/cards' className={({ isActive }) => (`${isActive ? 'text-white weight-bold text-decoration-underline' : 'text-white'} m-2 mx-3 noDecoration `)}><TbCards className='me-2 my-auto align-middle my-auto' style={{ width: '1.2rem', height: '1.2rem' }} />Cards</NavLink>
                    <NavLink to='/background' className={({ isActive }) => (`${isActive ? 'text-white weight-bold text-decoration-underline' : 'text-white'} m-2 mx-3 noDecoration `)}><BsFillImageFill className='me-2 my-auto align-middle my-auto' style={{ width: '1.2rem', height: '1.2rem' }} />Backgrounds</NavLink>
                    <NavLink to='/contacts' className={({ isActive }) => (`${isActive ? 'text-white weight-bold text-decoration-underline' : 'text-white'} m-2 mx-3 noDecoration `)}><AiOutlineContacts className='me-2 my-auto align-middle my-auto' style={{ width: '1.2rem', height: '1.2rem' }} />Contacts</NavLink>

                    <NavLink to='/settings' className={({ isActive }) => (`${isActive ? 'text-white weight-bold' : 'text-white'} m-2 mx-3 noDecoration `)}><AiOutlineSetting className='me-2 my-auto align-middle my-auto' style={{ width: '1.2rem', height: '1.2rem' }} />Settings</NavLink>

                </Nav>
            </div>
            <div className='text-center'>
               
                <hr />
                {/* <Button variant='link noDecoration text-white' onClick={() => setGetStarted(true)}>Get Started</Button> */}

                <Row className='text-center'>
                    <Col className='text-end'>
                        <a className='text-white' href='https://imaiditup.com/privacy.html'>Privacy</a>
                    </Col>
                    <Col className='text-start'>
                        <a className='text-white' href='https://imaiditup.com/privacy.html'>Terms</a>
                    </Col>
                </Row>

                <a href='mailto:support@justthetap.me' className='text-white'>Support</a>
            </div>
   

        </div>
    )
}