import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink, Link } from 'react-router-dom'

import { AiOutlineContacts, AiFillIdcard, AiOutlineSetting, AiOutlineTeam, AiOutlineClockCircle, AiOutlineNotification, AiOutlineSearch } from 'react-icons/ai'
import { FaBell, FaBellSlash } from 'react-icons/fa'
import { TbCards } from 'react-icons/tb'
import { BsFillImageFill } from 'react-icons/bs'
export default function TopBarNav(props) {
    return (
        <Navbar bg="white" expand="lg" className='shadow' >
            <Container className='text-white'>
                <Navbar.Brand href="#home"><img width='40' height='40' className='me-3' src={require('../assets/logo-trans.png')} alt='justthetap logo' />JustThe<strong className='mb'></strong>Tap</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavLink to='/cards' className={({ isActive }) => (`${isActive ? 'text-primary weight-bold text-decoration-underline' : 'text-black'} m-2 mx-3 noDecoration `)}><TbCards className='me-2 my-auto align-middle my-auto' style={{ width: '1.2rem', height: '1.2rem' }} />Cards</NavLink>
                        <NavLink to='/background' className={({ isActive }) => (`${isActive ? 'text-primary weight-bold text-decoration-underline' : 'text-black'} m-2 mx-3 noDecoration `)}><BsFillImageFill className='me-2 my-auto align-middle my-auto' style={{ width: '1.2rem', height: '1.2rem' }} />Backgrounds</NavLink>
                        <NavLink to='/contacts' className={({ isActive }) => (`${isActive ? 'text-primary weight-bold text-decoration-underline' : 'text-black'} m-2 mx-3 noDecoration `)}><AiOutlineContacts className='me-2 my-auto align-middle my-auto' style={{ width: '1.2rem', height: '1.2rem' }} />Contacts</NavLink>

                        <NavLink to='/settings' className={({ isActive }) => (`${isActive ? 'text-primary weight-bold' : 'text-black'} m-2 mx-3 noDecoration `)}><AiOutlineSetting className='me-2 my-auto align-middle my-auto' style={{ width: '1.2rem', height: '1.2rem' }} />Settings</NavLink>

                        <NavDropdown title="Legal" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/privacy.html">Privacy</NavDropdown.Item>
                            <NavDropdown.Item href="/terms.html">
                                Terms
                            </NavDropdown.Item>
                            <NavDropdown.Item href="mailto:support@justthetap.me">
                                Support
                            </NavDropdown.Item>

                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}