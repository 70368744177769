import { useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { AiFillCheckCircle } from 'react-icons/ai'
import API from '../../_helpers/api'
import Actions from '../../_redux/actions'
import { useNavigate } from 'react-router-dom'
export default function Complete(props) {


    const org = useSelector(state => state.org)
    const dispatch = useDispatch()
    const teams = useSelector(state => state.teams)
    const [loading, setLoading] = useState(false)
    const user = useSelector(state => state.user)
    const navigate = useNavigate()

    const _finish = async (updateTeam) => {
        setLoading(true)
        const info = await API.getUser()


        dispatch(Actions.setUser(info?.user))
        dispatch(Actions.setCards(info?.cards))

        navigate('/cards')

    }

    return (
        <Container className='p-3'>
            <Row>
                <Col md={8} className={`bg-white border shadow rounded my-auto mx-auto animate__animated animate__fadeInRight p-3 px-5`}>
                    <AiFillCheckCircle size='4rem' className='text-success' />
                    <h1>Signup Complete</h1>
                    <Row >
                        <Col md={7} className='mx-auto'>

                            <p className='lead mt-3'>You're All Set! You will be able to customize your JustTheTap card now!</p>
                            <Button onClick={() => _finish(true)} className='mt-4' disabled={loading} >Complete Signup </Button>
                            {loading && <>
                                <br />
                                <div className='text-center'>
                                    <Spinner animation='grow' className='mt-3' />
                                </div>
                            </>}
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Container>
    )
}