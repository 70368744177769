import logo from './logo.svg';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate, } from "react-router-dom";
import { useRef, useState, useContext, useEffect } from 'react';
import { Col, Container, Row, Spinner, Toast, ToastContainer } from "react-bootstrap";
import uuid from 'react-uuid';
import API from './_helpers/api';
import Login from './_screens/Login';
import Reset from './_screens/Reset'
import Signup from './_screens/Signup';
import SideBarNav from './_components/sidebarnav';
import TopBarNav from './_components/topbarnav';
import Actions from './_redux/actions';
import 'animate.css';

import Redirect from './_components/Redirect';
import Cards from './_screens/Cards';
import EditCard from './_screens/EditCard';
import MyCard from './_screens/MyCard';
import ViewCard from './_screens/ViewCard';
import Background from './_screens/Background';
import Settings from './_screens/Settings';
import Contacts from './_screens/Contacts';
import ReactGA from 'react-ga4'


const DEVICE_WIDTH = window.innerWidth

function App() {


  const showToast = useSelector(state => state.showToast)
  const toastHeader = useSelector(state => state.toastHeader)
  const toastMsg = useSelector(state => state.toastMsg)
  const user = useSelector(state => state.user)


  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [initialLoad, setInitialLoad] = useState(true)
  useEffect(() => {

    API.wakeUp().then(() => {
      setTimeout(() => {
        setInitialLoad(false)
        _checkForUser()
      }, 800)

    })
  }, [])


  const _checkForUser = async () => {
    const token = localStorage.getItem('jwt')
    if (token) {
      const info = await API.getUser()

      if (!info.user) {
        if (!window.location.href.includes('reset') && !window.location.href.includes('/hi')) {
          navigate('/login', { replace: true })
          return
        }
      }
      dispatch(Actions.setUser(info?.user))
      dispatch(Actions.setCards(info?.cards))

      if (process.env.REACT_APP_ENV === 'production') {
        ReactGA.set({ userId: info?.user?._id });

      }

      if (window.location.href.includes('login')) {
        navigate('/cards', { replace: true })
      }

    } else {
      if (!window.location.href.includes('reset') && !window.location.href.includes('/hi')) {
        navigate('/login', { replace: true })
      }
    }
  }




  return (
    <div className="App h-100">

      {initialLoad ? <>

        <Container fluid className='vh-100 bg-white'>
          <Row className='h-100 align-items-center'>
            <Col>
              <Row>
                <Col md={3} className='mx-auto'>
                  <img className='img-fluid' src={require('./assets/logo.png')} alt='justthtaplogo' />

                </Col>
              </Row>
              <Spinner animation='border' variant='primary' />

            </Col>
          </Row>
        </Container>

      </> : <>


        {DEVICE_WIDTH > 1024 ? <Row className='m-0'>

          {user && !window.location.href.includes('signup') && !window.location.href.includes('login') && !window.location.href.includes('reset') && !window.location.href.includes('/hi/') && <Col md={2} className='m-0 p-0'>
            <SideBarNav />
          </Col>}
          <Col md={user && !window.location.href.includes('reset') && !window.location.href.includes('login') && !window.location.href.includes('signup') && !window.location.href.includes('/hi/') ? 10 : 12} className='vh-100 m-0 p-0' style={{ overflowY: 'scroll', }}>
            <Router />
          </Col>
        </Row> : <>
          {user && !window.location.href.includes('signup') && !window.location.href.includes('login') && !window.location.href.includes('reset') && !window.location.href.includes('/hi/') && <TopBarNav />}
          <Router />

        </>}
        <ToastContainer position="bottom-end" className="p-3">
          <Toast onClose={() => dispatch(Actions.clearToast())} show={showToast} delay={4000} autohide>
            <Toast.Header>
              <strong className='me-auto'>{toastHeader}</strong>
            </Toast.Header>
            <Toast.Body>{toastMsg}</Toast.Body>
          </Toast>
        </ToastContainer>
      </>}
    </div>

  )

}


function Router() {

  return (
    <Routes>
      <Route path="/login" exact element={<Login />} />
      <Route path="/signup" exact element={<Signup />} />
      <Route path="/cards" exact element={<Cards />} />
      <Route path="/contacts" exact element={<Contacts />} />

      <Route path="/cards/:id" exact element={<MyCard />} />
      <Route path="/cards/:id/edit" exact element={<EditCard />} />
      <Route path="/background" exact element={<Background />} />
      <Route path="/settings" exact element={<Settings />} />

      <Route path="/hi/:cardId" exact element={<ViewCard />} />

      <Route path="/reset" exact element={<Reset />} />
      <Route path="/" exact element={<Redirect />} />

    </Routes>
  )
}

export default App;
