const ActionTypes = {
    SET_USER: 'SET_USER',
    SET_CARDS: 'SET_CARDS',
    SET_SELECTED_CARD: 'SET_SELECTED_CARD',
    NEXT_SIGNUP: 'NEXT_SIGNUP',
    SET_TOAST: 'SET_TOAST',
    CLEAR_TOAST: 'CLEAR_TOAST'

}

export default ActionTypes