import { Container, Row, Col, Form, Button, Spinner, Alert, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsCardText } from 'react-icons/bs'
import API from "../../_helpers/api";
import { useDispatch } from "react-redux";
import Actions from "../../_redux/actions";

export default function ClaimTag(props) {


    const [nanoid, setNanoid] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()


    useEffect(() => {

    }, [])

    const _claimTag = async (e) => {
        e.preventDefault()
        setLoading(true)
        setErrMsg('')
        const res = await API.claimCard(nanoid, password)
        if (res.isErr) {
            setErrMsg(res.errMsg)
            setLoading(false)
        } else {
            dispatch(Actions.setCards([res.card]))
            dispatch(Actions.setSelectedCard(res.card))
            dispatch(Actions.nextSignup())
            setLoading(false)
        }
    }

    return (
        <Container className='p-4 pt-0 text-center vh-100 '>

            <Row className='border shadow rounded mx-auto animate__animated animate__fadeInRight  my-auto ' style={{ height: '75%' }}>
                <Col md={{order : 1, span : 6}} xs={{ order: 2 }} className='bg-primary  h-100 d-flex flex-column justify-content-center'>
                    <div>
                        <BsCardText className='text-white ' size='8rem' />
                        <h2 className="text-white">Find this information on the card received in the mail with your Tapper or in the email sent when purchased</h2>
                    </div>
                </Col>

                <Col md={6} className='mx-auto my-auto p-4' xs={{ order: 1 }}>
                    <Row>
                        <Col md={8} className='mx-auto' >


                            <Form className='mt-4' onSubmit={_claimTag}>
                                <Form.Group className="mb-3 text-start">
                                    <Form.Label className='text-start'>Tapper ID</Form.Label>
                                    <Form.Control required type="text" value={nanoid} onChange={e => setNanoid(e.target.value)} placeholder="Tapper ID" />
                                </Form.Group>

                                <Form.Group className="mb-3 text-start">
                                    <Form.Label className='text-start'>Tapper Password</Form.Label>
                                    <Form.Control required type="text" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
                                </Form.Group>

                                <Button type='submit' disabled={loading}>Register Tag</Button>
                                {loading && <div className='text-center mt-2'>
                                    <Spinner animation="border" />
                                </div>}
                            </Form>
                            <p className='mt-3'><small>NOTE: Tapper ID's and Password's Are Case Sensitive</small></p>
                            {errMsg !== '' && <Alert variant='danger m-3 text-center p-1'>{errMsg}</Alert>}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}