import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Button, Tabs, Tab, Form, ButtonGroup, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeftCircle, BsPencil, BsCloudDownload, BsGear, BsFillEnvelopeFill, BsFillTelephoneFill, } from 'react-icons/bs'
import { AiFillPlusCircle, AiFillLinkedin, AiFillFacebook, AiOutlineCheck } from 'react-icons/ai'
import { Draggable } from "react-drag-reorder";

import ReactGA from 'react-ga4'

import Actions from "../_redux/actions";
import API from "../_helpers/api";
import Field from "../_components/field";
import ShownField from "../_components/ShownField";




//TODO: ADD PERSONALIZED LINK AS PRO OPTION
export default function Edit(props) {
    const { id } = useParams()
    const cards = useSelector(state => state.cards)
    const selectedCard = useSelector(state => state.selectedCard)
    const user = useSelector(state => state.user)

    const [showNewField, setShowNewField] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [boxes, setBoxes] = useState([]);

    const [nickname, setNickname] = useState('')
    const [includePronouns, setIncludePronouns] = useState(false)
    const [title, setTitle] = useState('')
    const [department, setDepartment] = useState('')
    const [company, setCompany] = useState('')

    const [layout, setLayout] = useState(1)
    const [color, setColor] = useState('')

    const [loading, setLoading] = useState(false)


    const [flash, setFlash] = useState(true)


    const [dragId, setDragId] = useState();

    const handleDrag = (ev) => {
        console.log(ev.currentTarget)
        setDragId(ev.currentTarget.id);
    };

    useEffect(() => {

        if (process.env.REACT_APP_ENV === 'production') {
            ReactGA.send({ hitType: "pageview", page: "/cards/edit", title: "Card Edit" });
        }

        if (cards && !selectedCard) {
            const temp = cards?.find(card => card._id === id)
            dispatch(Actions.setSelectedCard(cards?.find(card => card._id === id)))
        }

        document.title = 'JustTheTap - Edit Card'

    }, [cards])

    useEffect(() => {
        if (selectedCard) {


            setNickname(selectedCard?.nickname)
            setIncludePronouns(selectedCard?.includePronouns)
            setColor(selectedCard?.color)
            setTitle(selectedCard.title)
            setDepartment(selectedCard.department)
            setCompany(selectedCard.company)
            setLayout(selectedCard.layout)
            setColor(selectedCard.color)
            setBoxes(selectedCard.fields)
        }
    }, [selectedCard])


    const _swap = async (currentPos, direction) => {
        console.log(`SWAP ELEMENT ${currentPos}, ${direction}`)
        const fieldArr = JSON.parse(JSON.stringify(boxes.sort((a, b) => a.order - b.order)))

        const newPos = currentPos + (direction === 'UP' ? -1 : 1)
        console.log('NEW POSITION: ', newPos)
        const tempElement = JSON.parse(JSON.stringify(fieldArr[newPos]))
        const currentElementId = fieldArr[currentPos]._id


        const tempOrder = fieldArr[newPos].order
        fieldArr[newPos].order = fieldArr[currentPos].order
        fieldArr[currentPos].order = tempOrder

        const tempCard = JSON.parse(JSON.stringify(selectedCard))
        tempCard.fields = fieldArr

        setBoxes(fieldArr)
        console.log(fieldArr)

        const res = await API.updateCard(tempCard)

        dispatch(Actions.setCards(res.cards))
        dispatch(Actions.setSelectedCard(res.updated))

        dispatch(Actions.setToast('✅ Changes Save', 'Your Field Reorder has been saved'))
        console.log('===== UPDATED CARDS =======')
        console.log(res.cards)
        setFlash(false)

        setTimeout(() => {
            setFlash(true)
            if (newPos !== 0) {

                setTimeout(() => {
                    const curr = document.getElementById(currentElementId)

                    curr?.scrollIntoView({ behavior: 'smooth' })

                }, 100)
            }


        }, 100)

        if (process.env.REACT_APP_ENV === 'production') {
            ReactGA.event({
                category: 'ButtonClick',
                action: 'swapField',
                label: 'Update Field Order'
            });
        }
    }


    const _changeColor = (col) => {
        setColor(col)
    }

    const _updateCard = async () => {
        setLoading(true)

        const tempCard = JSON.parse(JSON.stringify(selectedCard))
        tempCard.nickname = nickname
        tempCard.includePronouns = includePronouns
        tempCard.title = title
        tempCard.department = department
        tempCard.company = company
        tempCard.layout = layout
        tempCard.color = color

        const res = await API.updateCard(tempCard)

        dispatch(Actions.setCards(res.cards))
        dispatch(Actions.setSelectedCard(res.updated))
        setLoading(false)
        dispatch(Actions.setToast('✅ Changes Save', 'People you meet will see these changes immediately'))

        if (process.env.REACT_APP_ENV === 'production') {
            ReactGA.event({
                category: 'ButtonClick',
                action: 'updateCard',
                label: 'Update Card'
            });
        }
    }

    return (
        <Container fluid className="p-5 px-4">
            <div className='text-primary text-start my-2 mb-3'>
                <BsArrowLeftCircle title='Back' size='1.5rem' className='pointer me-5' onClick={() => navigate(`/cards/${id}`)} />
            </div>
            <Row className="mb-5 pb-5">
                <Col md={4} className='p-1' >
                    <div className='border shadow rounded p-0 bg-white top-round'>
                        {layout === 0 && <>
                            {user?.hasProfilePic && <img className='img-fluid' src={`https://justthetap.s3.amazonaws.com/profile-pic/${selectedCard?.belongsTo}?${(new Date()).getTime()}`} alt='profilePic' />}
                            {!user?.hasProfilePic && <img className='img-fluid' src={require('../assets/profile.jpg')} alt='profilePic' />}

                            <div className={`py-1 bg-${color} mb-3`} />
                        </>}
                        {layout === 1 && <>
                            <div className={`halfish-${color} p-4`}>

                                {user?.hasProfilePic && <img className='img-fluid img-rounded' style={{ borderRadius: '50%', borderWidth: 5, borderColor: '#efefef', borderStyle: 'solid' }} src={`https://justthetap.s3.amazonaws.com/profile-pic/${selectedCard?.belongsTo}`} alt='profilePic' />}
                                {!user?.hasProfilePic && <img className='img-fluid img-rounded' style={{ borderRadius: '50%', borderWidth: 5, borderColor: '#efefef', borderStyle: 'solid' }} src={require('../assets/profile.jpg')} alt='profilePic' />}


                            </div>
                        </>}
                        <h2>{user?.prefix} {user?.fName} {user?.lName} {user?.suffix}</h2>
                        {includePronouns && <p>{user?.pronouns}</p>}
                        <hr className='mx-5' />
                        {title !== '' && <h5 className="weight-bold">{title}</h5>}
                        {department !== '' && <h5 className={`weight-bold text-${color}`} >{department}</h5>}
                        {company !== '' && <h5 ><em>{company}</em></h5>}

                        <div className='p-3'>
                            {flash && color && boxes.sort((a, b) => a.order - b.order)?.map(field => (
                                <ShownField field={field} color={color} />
                            ))}
                        </div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className='border shadow rounded p-3 bg-white'>
                        <div className='text-end mb-2'>
                            <Button disabled={loading} onClick={_updateCard}>Save Changes {loading && <Spinner animation="border" size='sm' />}</Button>
                        </div>
                        <Tabs
                            defaultActiveKey="general"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="general" title="General">
                                <Row>
                                    <Col md={8} className='text-start ps-3'>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Card's Nickname</Form.Label>
                                            <Form.Control value={nickname} onChange={e => setNickname(e.target.value)} type="text" placeholder="Nickname" />
                                            <Form.Text className="text-muted">
                                                This value is only shown to you and never to people you meet.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Check
                                            type={'checkbox'}
                                            id={`default-pronouns`}
                                            label={`Include Pronouns`}
                                            checked={includePronouns}
                                            onClick={() => setIncludePronouns(value => !value)}
                                        />
                                        <h3 className='mt-4'>Position</h3>
                                        <hr />
                                        <Form.Group className="mb-3">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control value={title} onChange={e => setTitle(e.target.value)} type="text" placeholder="Title" />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Department</Form.Label>
                                            <Form.Control value={department} onChange={e => setDepartment(e.target.value)} type="text" placeholder="Department" />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Company</Form.Label>
                                            <Form.Control value={company} onChange={e => setCompany(e.target.value)} type="text" placeholder="Company" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="design" title="Design">
                                <Row>
                                    <Col md={8} className='text-start ps-3'>

                                        <h3 className='mt-1 text-start'>Layout</h3>
                                        <hr />
                                        <ButtonGroup aria-label="Basic example">
                                            <Button onClick={() => setLayout(0)} variant={layout === 0 ? 'primary' : 'grey'} className={`${layout === 0 ? 'border weight-bold' : ''}`}>Original</Button>
                                            <Button variant={layout === 1 ? 'primary' : 'grey'} onClick={() => setLayout(1)} className={`${layout === 1 ? 'border weight-bold' : ''}`}>Modern</Button>
                                        </ButtonGroup>

                                        <h3 className='mt-4 text-start'>Color</h3>
                                        <hr />
                                        <Row className='flex-wrap flex-wrap-start justify-content-center align-items-center'>
                                            <Col>
                                                <div onClick={() => _changeColor('primary')} className='color-btn bg-primary d-flex flex-column justify-content-center align-items-center text-white'>
                                                    {color === 'primary' && <AiOutlineCheck />}
                                                </div>
                                            </Col>
                                            <Col>
                                                <div onClick={() => _changeColor('secondary')} className='color-btn bg-secondary d-flex flex-column justify-content-center align-items-center text-white'>
                                                    {color === 'secondary' && <AiOutlineCheck />}

                                                </div>
                                            </Col>

                                            <Col>
                                                <div onClick={() => _changeColor('success')} className='color-btn bg-success d-flex flex-column justify-content-center align-items-center text-white'>
                                                    {color === 'success' && <AiOutlineCheck />}

                                                </div>
                                            </Col>

                                            <Col>
                                                <div onClick={() => _changeColor('warning')} className='color-btn bg-warning d-flex flex-column justify-content-center align-items-center text-white'>
                                                    {color === 'warning' && <AiOutlineCheck />}

                                                </div>
                                            </Col>

                                            <Col>
                                                <div onClick={() => _changeColor('danger')} className='color-btn bg-danger d-flex flex-column justify-content-center align-items-center text-white'>
                                                    {color === 'danger' && <AiOutlineCheck />}

                                                </div>
                                            </Col>

                                            <Col>
                                                <div onClick={() => _changeColor('orange')} className='color-btn bg-orange d-flex flex-column justify-content-center align-items-center text-white'>
                                                    {color === 'orange' && <AiOutlineCheck />}
                                                </div>
                                            </Col>

                                            <Col>
                                                <div onClick={() => _changeColor('dark')} className='color-btn bg-dark d-flex flex-column justify-content-center align-items-center text-white'>
                                                    {color === 'dark' && <AiOutlineCheck />}

                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="fields" title="Fields" >
                                <Row>
                                    <Col md={10} className='text-start ps-3'>
                                        <div className='text-end'>
                                            <AiFillPlusCircle onClick={() => setShowNewField(true)} title='Add Field' size='2rem' className='text-success pointer' />
                                        </div>

                                        {showNewField && <Field newOne={true} close={() => setShowNewField(false)} />}


                                        {flash && boxes
                                            ?.sort((a, b) => a.order - b.order)
                                            .map((field, index) => (
                                                <Field
                                                    total={boxes?.length}
                                                    key={index}
                                                    index={index}
                                                    swap={_swap}
                                                    field={field}
                                                    boxNumber={field.order}
                                                />

                                            ))}


                                        {selectedCard?.fields?.length === 0 && <p className='text-center'>Add Your First Field</p>}
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </div>
                </Col>



            </Row>
        </Container >
    )
}