import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiFillPlusCircle } from 'react-icons/ai'
import Actions from "../_redux/actions";
import { useEffect, useState } from "react";
import ClaimTapper from "../_components/ClaimTapper";
import API from "../_helpers/api";
import ReactGA from 'react-ga4'

export default function Contacts(props) {

    const [contacts, setContacts] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showNew, setShowNew] = useState(false)


    useEffect(() => {
        _getContacts()
        if (process.env.REACT_APP_ENV === 'production') {
            ReactGA.send({ hitType: "pageview", page: "/contacts", title: "Contacts Viewed" });
        }
    }, [])

    const _selectCard = async (card) => {
        dispatch(Actions.setSelectedCard(card))
        navigate(`/cards/${card._id}`)
      
    }

    const _getContacts = async () => {
        const res = await API.getContacts()
        setContacts(res.contacts)
        console.log(res.contacts)
    }

    const _open = (location) => {
        window.open(location)
    }
    return (
        <Container className='p-3'>
            <h1 className='text-start'>Your Contacts</h1>

            <Row >


                {contacts?.map(card => (
                    <Col md={3} onClick={() => _open(`/hi/${card.nanoid}?t=contact`)} className='p-1 h-100'>
                        <div className='border shadow rounded p-0 pointer grow bg-white h-100'>

                            {card.layout === 0 && <>



                                <img className='img-fluid' src={`https://justthetap.s3.amazonaws.com/profile-pic/${card?.belongsTo?._id}`} alt='profilePic' />

                                <div className={`py-1 bg-${card.color}`} />
                                <h3>{card?.belongsTo.fName} {card?.belongsTo.lName}</h3>
                            </>}


                            {card.layout === 1 && <>

                                <div className={`halfish-${card.color} p-4`}>

                                    <img className='img-fluid img-rounded' style={{ borderRadius: '50%', borderWidth: 5, borderColor: '#efefef', borderStyle: 'solid' }} src={`https://justthetap.s3.amazonaws.com/profile-pic/${card?.belongsTo?._id}`} alt='profilePic' />


                                </div>
                                <h3>{card?.belongsTo.fName} {card?.belongsTo.lName}</h3>

                            </>}
                        </div>
                    </Col>


                ))}


            </Row>

            {contacts?.length === 0 && <p className='lead text-start'>Looks like you don't have any contacts yet. Log in to JustTheTap on your mobile device, and when you meet another JustTheTap user, their information will be saved here!</p>}


            <Modal show={showNew} size='lg' static onHide={() => setShowNew(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <ClaimTapper close={() => setShowNew(false)} />
                </Modal.Body>

            </Modal>

        </Container>
    )
}