import { useEffect, useState } from "react";
import { Col, Container, Row, Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeftCircle, BsPencil, BsCloudDownload, BsGear, BsFillEnvelopeFill, BsFillTelephoneFill } from 'react-icons/bs'
import { AiFillTwitterCircle, AiFillLinkedin, AiFillFacebook } from 'react-icons/ai'
import { MdOutlineLocalShipping } from 'react-icons/md'
import { FaCopy } from 'react-icons/fa'
import { QRCode } from 'react-qrcode-logo';
import Chart from "react-apexcharts";
import html2canvas from "html2canvas";
import ReactGA from 'react-ga4'

import Actions from "../_redux/actions";
import ShownField from "../_components/ShownField";
import API from "../_helpers/api";
import Replace from "../_components/Replace";




//TODO: ADD PERSONALIZED LINK AS PRO OPTION
export default function MyCard(props) {
    const { id } = useParams()
    const [analytics, setAnalytics] = useState([])

    const [time, setTime] = useState('DAY')
    const [showReplace, setShowReplace] = useState(false)
    const cards = useSelector(state => state.cards)
    const selectedCard = useSelector(state => state.selectedCard)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [set, setSet] = useState(false)
    const [options, setOptions] = useState({
        chart: {
            id: "line"
        },
        xaxis: {
            categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        }
    })

    const [series, setSeries] = useState([
        {
            name: "series-1",
            data: [30, 40, 45, 50, 49, 60, 70, 91]
        }
    ])

    useEffect(() => {
        if (cards && !selectedCard) {
            dispatch(Actions.setSelectedCard(cards?.find(card => card._id === id)))
            _getAnalytic('DAY')

        }

        if (process.env.REACT_APP_ENV === 'production' && !set) {
            ReactGA.send({ hitType: "pageview", page: "/my-card", title: "My Card Viewed" });
            setSet(true)
        }
    }, [cards])

    const _logPreview = () => {
        if (process.env.REACT_APP_ENV === 'production') {
            ReactGA.event({
                category: 'ButtonClick',
                action: 'previewCard',
                label: 'Preview Card'
            });
        }
    }


    const _formatOptions = (events) => {




    }

    const _getAnalytic = async (timeFrame) => {
        setTime(timeFrame)
        const res2 = await API.getAnalytic(id, timeFrame)
        console.log(res2.analytics)
        setAnalytics(res2.analytics)
    }

    const _selectCard = async (card) => {
        dispatch(Actions.setSelectedCard(card))
        navigate(`/cards/${card._id}`)

    }

    const _downloadQRCode = async () => {
        const canvas = await html2canvas(document.getElementById('qr-code'));
        const image = canvas.toDataURL("image/png", 1);

        const aTag = document.createElement('a')
        aTag.setAttribute('download', selectedCard?.nickname?.replaceAll(' ', '_') + '.png')
        aTag.setAttribute('href', image)

        document.body.appendChild(aTag);

        aTag.click()
        aTag.remove()
    }



    const _copy = async () => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/hi/${selectedCard?.nanoid}?t=link`);
        } else {
            document.execCommand('copy', true, `${process.env.REACT_APP_URL}/hi/${selectedCard?.nanoid}?t=link`);
        }
        dispatch(Actions.setToast('✅ Link Copied To Clipboard', 'You may now paste this link'))
    }

    return (
        <Container className="p-5 px-4 pb-5">
            <div className='text-primary text-start my-2 mb-3'>
                <BsArrowLeftCircle title='Back' size='1.5rem' className='pointer me-5' onClick={() => navigate('/cards')} />
                <BsPencil title='Edit' size='1.5rem' className='pointer me-5' onClick={() => navigate(`/cards/${id}/edit`)} />
                <BsCloudDownload onClick={_downloadQRCode} title='Download QR Code' size='1.5rem' className='pointer me-5' />
                {!selectedCard?.isVirtual && <MdOutlineLocalShipping title='Replace Damaged Tapper' size='1.5rem' className='pointer me-5' onClick={() => setShowReplace(true)} />}
            </div>
            <Row className="h-100 pb-5 mb-5">

                <Col md={4} className='p-1' >
                    <div className='border shadow rounded p-0 bg-white h-100'>
                        {selectedCard?.layout === 0 && <>

                            {user?.hasProfilePic && <img className='img-fluid' src={`https://justthetap.s3.amazonaws.com/profile-pic/${user?._id}?${(new Date()).getTime()}`} alt='profilePic' />}
                            {!user?.hasProfilePic && <img className='img-fluid' src={require('../assets/profile.jpg')} alt='profilePic' />}
                            <div className={`py-1 bg-${selectedCard.color}`} />
                        </>}


                        {selectedCard?.layout === 1 && <>

                            <div className={`halfish-${selectedCard.color} p-4`}>

                                {user?.hasProfilePic && <img className='img-fluid img-rounded' style={{ borderRadius: '50%', borderWidth: 5, borderColor: '#efefef', borderStyle: 'solid' }} src={`https://justthetap.s3.amazonaws.com/profile-pic/${user?._id}`} alt='profilePic' />}
                                {!user?.hasProfilePic && <img className='img-fluid img-rounded' style={{ borderRadius: '50%', borderWidth: 5, borderColor: '#efefef', borderStyle: 'solid' }} src={require('../assets/profile.jpg')} alt='profilePic' />}



                            </div>

                        </>}
                        <h2>{user?.prefix} {user?.fName} {user?.lName} {user?.suffix}</h2>
                        {selectedCard?.includePronouns && <p>{user?.pronouns}</p>}
                        <hr className='mx-5' />
                        {selectedCard?.title !== '' && <h5 className="weight-bold">{selectedCard?.title}</h5>}
                        {selectedCard?.department !== '' && <h5 className={`weight-bold text-${selectedCard?.color}`} >{selectedCard?.department}</h5>}
                        {selectedCard?.company !== '' && <h5 ><em>{selectedCard?.company}</em></h5>}

                        <div className='p-3'>
                            {selectedCard?.fields?.sort((a, b) => a.order - b.order)?.map(field => (
                                <ShownField field={field} color={selectedCard.color} />
                            ))}
                        </div>
                    </div>
                </Col>

                <Col md={4} className='p-1 h-100' >
                    <div className='border shadow rounded p-3 bg-white  h-100'>
                        <Row>
                            <Col md={6} className='ms-auto text-end'>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label >TimeFrame</Form.Label>
                                    <Form.Select value={time} required onChange={e => _getAnalytic(e.target.value)} >
                                        <option value='DAY'>24 Hours</option>
                                        <option value='WEEK'>Week</option>
                                        <option value='MONTH'>Month</option>

                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>


                        <h3>Analytics</h3>
                        <Row className='m-2'>
                            <Col className=' my-auto text-center mx-auto p-2 border shadow m-2 rounded' sm={9}>
                                <div>
                                    <h4 className="weight-bold" >{analytics?.filter(anal => anal?.eventType === 'NFC')?.length}</h4>
                                    <p>Tap Events</p>
                                </div>
                            </Col>
                            {/* <Col sm={9}>
                                <Chart
                                    options={options}
                                    series={series}
                                    type="line"

                                />
                            </Col> */}
                        </Row>
                        <Row className='m-2'>
                            <Col className=' my-auto text-center mx-auto p-2 border shadow m-2 rounded' sm={9}>
                                <div>
                                    <h4 className="weight-bold" >{analytics?.filter(anal => anal?.eventType === 'QR')?.length}</h4>
                                    <p>QR Scans</p>
                                </div>
                            </Col>

                        </Row>
                        <Row className='m-2'>
                            <Col className=' my-auto text-center mx-auto p-2 border shadow m-2 rounded' sm={9}>
                                <div>
                                    <h4 className="weight-bold" >{analytics?.filter(anal => anal?.eventType === 'CONTACT_ADDED')?.length}</h4>
                                    <p>Contact Downloads</p>
                                </div>

                            </Col>

                        </Row>
                        <Row className='m-2'>
                            <Col className=' my-auto text-center mx-auto p-2 border shadow m-2 rounded' sm={9}>
                                <div>
                                    <h4 className="weight-bold" >{analytics?.filter(anal => anal?.eventType === 'FIELD_CLICK')?.length}</h4>
                                    <p>Links Clicked</p>
                                </div>

                            </Col>

                        </Row>



                    </div>
                </Col>

                <Col md={4} className='p-1'  >
                    <div className='border shadow rounded p-0 bg-white p-3 h-100'>
                        <h3>Send Card</h3>
                        <div className='pointer grow w-100' id='qr-code'
                        >
                            <a onClick={_logPreview} href={`${process.env.REACT_APP_URL}/hi/${selectedCard?.nanoid}?t=preview`} target="_blank" rel="noreferrer" >

                                <QRCode
                                    value={`${process.env.REACT_APP_URL}/hi/${selectedCard?.nanoid}?t=qr`}
                                    logoImage="/logo.png"
                                    logoWidth={100}
                                    size={300}
                                    logoHeight={100}
                                    qrStyle='dots'
                                    fgColor='#3a86ff'
                                />
                            </a>
                        </div>
                        <p>Scan or Click to View</p>

                        <Button onClick={_copy}><FaCopy /> Copy Link</Button>
                        <div className='text-danger mt-3'>
                            <AiFillTwitterCircle size='2rem' className='me-3' />
                            <AiFillLinkedin size='2rem' className='me-3' />

                            <AiFillFacebook size='2rem' className='me-3' />

                        </div>
                    </div>
                </Col>

            </Row>


            <Modal show={showReplace} size='lg' static onHide={() => setShowReplace(false)}>
                <Modal.Header closeButton>
                    Request a Replacement
                </Modal.Header>
                <Modal.Body>
                    <Replace cardID={id} close={() => setShowReplace(false)} />
                </Modal.Body>

            </Modal>

        </Container>
    )
}