import { Container, Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import { useState } from "react";

import Logo from '../assets/logo-trans.png'
import API from '../_helpers/api'
import { AiFillShopping } from 'react-icons/ai'
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Actions from "../_redux/actions";
export default function Login(props) {


    const [email, setEmail] = useState('')
    const [pw, setPw] = useState('')
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(false)
    const [adminErr, setAdminErr] = useState(false)
    const [sent, setSent] = useState(false)
    const [sentErr, setSentErr] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const login = async (e) => {
        e.preventDefault()
        setErr(false)
        setLoading(true)

        const res = await API.login(email, pw)
        if (res.errMsg) {
            setErr(true)
            setPw('')
        } else {
            // const tags = await API.getUserTags()
            // dispatch(Actions.setUserTags(tags))
            dispatch(Actions.setUser(res.user))
            navigate('/')
        }

        setLoading(false)

    }

    const _reset = async () => {
        setSentErr('')
        setSent(false)
        if (!email) {
            setSentErr('Please Enter Your Email Above to Reset Your Password')
            return
        }
        if (!email.includes('@')) {
            setSentErr('Please Enter a Valid Email Address')
            return
        }
        setLoading(true)
        await API.forgotPassword(email)
        setLoading(false)
        setSent(true)
    }


    return (

        <Container className='p-4 text-center px-0 pt-0 mt-0'>


            <Row className='animate__animated animate__fadeIn mt-md-5'>
                <Col md={{ span: 6, order: 1 }} xs={{ order: 2 }} className='bg-primary rounded p-3' style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                    <div className='d-flex flex-column align-items-center justify-content-center h-100 p-3'>
                        <div className='text-white'>
                            <h1 className='text-white mt-2'>Say Hello to the Last Business Card You'll Ever Need </h1>
                            <p>Share Your Information with just a <strong>tap</strong></p>
                            <a href='https://shop.justthetap.me' className='btn btn-primary bg-white text-primary'><AiFillShopping className='me-2' />Shop Our Store</a>
                        </div>
                    </div>

                </Col>
                <Col md={{ span: 6, order: 2 }} xs={{ order: 1 }} className='border shadow-lg rounded p-4'>
                    <Row>
                        <Col xs={4} className='mx-auto'>
                            <img src={Logo} className='img-fluid rounded' alt='logo' />

                        </Col>
                    </Row>
                    <Row>
                        <Col md={7} className='mx-auto'>
                            <Form className='mt-4' onSubmit={login}>
                                <Form.Group className="mb-3 text-start">
                                    <Form.Label className='text-start'>Email</Form.Label>
                                    <Form.Control size='lg' required type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                                </Form.Group>
                                <Form.Group className="mb-3 text-start" >
                                    <Form.Label className='text-start'>Password</Form.Label>
                                    <Form.Control size='lg' required type="password" placeholder="Password" value={pw} onChange={e => setPw(e.target.value)} />
                                </Form.Group>

                                <Button type='submit' variant='primary px-5 text-white' disabled={loading}>Login</Button>
                                {loading && <div className='text-center mt-2'>
                                    <Spinner animation="border" />
                                </div>}
                                {adminErr && <Alert variant='danger p-2 mt-2'>Only Account Admins Can Login to this Portal</Alert>}
                                {sentErr !== '' && <Alert variant='danger p-2 mt-2'>{sentErr}</Alert>}
                                {sent && <Alert variant='success p-2 mt-2'>An email with a reset link has been sent to <b>{email}</b></Alert>}

                                {err && <Alert variant='danger p-2 mt-2'>Your email or password is incorrect</Alert>}
                                <br />
                                <Button variant="link noDecoration mt-3" onClick={() => navigate('/signup')}>Create an Account</Button>

                                <hr className="mx-5" />
                                <Button variant="link" onClick={_reset}>Forgot Password?</Button>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>


        </Container >
    )
}