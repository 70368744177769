import { useState } from "react";
import { Alert, Button, Container, Form, Spinner, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import API from "../_helpers/api";
import Actions from "../_redux/actions";

export default function ClaimTapper(props) {

    const [step, setStep] = useState(0)
    const [nanoid, setNanoid] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [errMsg, setErrMsg] = useState('')

    const dispatch = useDispatch()

    const _claimTag = async (e) => {
        e.preventDefault()
        setLoading(true)
        setErrMsg('')
        const res = await API.claimCard(nanoid, password)
        if (res.isErr === true) {
            setErrMsg(res.errMsg)
            setLoading(false)
        } else {
            const res2 = await API.getUser()
            dispatch(Actions.setCards(res2.cards))
            dispatch(Actions.setSelectedCard(res.card))
            setLoading(false)
            dispatch(Actions.nextSignup())
            dispatch(Actions.setToast('✅ New Card Claimed', 'Click Your New Card to Edit!'))
            props.close()
        }
    }

    return (
        <Container className="p-3 text-center">
            {step === 0 && <div className='animate__animated animate__fadeIn'>
                <h2>Do You Have Another JustTheTap Key and Password to Claim?</h2>

                <Button onClick={() => setStep(1)} className='my-4'>Yes, I have a JustTheTap Key and Password</Button>
                <br />
                <a
                    href='https://shop.justthetap.me/product/justthetap-tapper/' target={"__blank"} className='mt-5'>No, I need to Buy Another</a>
            </div>}

            {step === 1 && <div className='animate__animated animate__fadeIn'>
                <h2>Enter Tapper Details</h2>
                <Row>
                    <Col md={6} className='mx-auto'>


                        <Form className='mt-4' onSubmit={_claimTag}>
                            <Form.Group className="mb-3 text-start">
                                <Form.Label className='text-start'>Tapper ID</Form.Label>
                                <Form.Control required type="text" value={nanoid} onChange={e => setNanoid(e.target.value)} placeholder="Tapper ID" />
                            </Form.Group>

                            <Form.Group className="mb-3 text-start">
                                <Form.Label className='text-start'>Tapper Password</Form.Label>
                                <Form.Control required type="text" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
                            </Form.Group>

                            <Button type='submit' disabled={loading || nanoid === '' || password === ''}>Register Tag</Button>
                            {loading && <div className='text-center mt-2'>
                                <Spinner animation="border" />
                            </div>}
                        </Form>
                        <p className='mt-3'><small>NOTE: Tapper ID's and Password's Are Case Sensitive</small></p>
                        {errMsg !== '' && <Alert variant='danger m-3 text-center p-1'>{errMsg}</Alert>}
                    </Col>
                </Row>
            </div>}
        </Container>
    )
}