import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiFillPlusCircle, AiFillShopping } from 'react-icons/ai'
import Actions from "../_redux/actions";
import { useEffect, useState } from "react";
import ClaimTapper from "../_components/ClaimTapper";
import ReactGA from 'react-ga4'

export default function Cards(props) {
    const cards = useSelector(state => state.cards)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(state => state.user)
    const [showNew, setShowNew] = useState(false)
    const _selectCard = async (card) => {

        if (process.env.REACT_APP_ENV === 'production') {
            ReactGA.event({
                category: 'ButtonClick',
                action: 'editCardClick',
                label: 'EditCard'
            });
        }
        dispatch(Actions.setSelectedCard(card))
        navigate(`/cards/${card._id}`)

    }


    const _logAdd = () => {
        if (process.env.REACT_APP_ENV === 'production') {
            ReactGA.event({
                category: 'ButtonClick',
                action: 'addNewCard',
                label: 'Add New Card'
            });
        }
    }
    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            ReactGA.send({ hitType: "pageview", page: "/cards", title: "Cards Viewed" });
        }
        document.title = 'JustTheTap - Cards'
    }, [])
    return (
        <Container className='p-3'>
            <h1 className='text-start'>Cards</h1>

            <div className='text-end'>
                <Button variant='success text-white' onClick={() => { setShowNew(true); _logAdd() }}>Add Card <AiFillPlusCircle /></Button>
            </div>
            <Row >


                {cards?.map(card => (
                    <Col md={3} onClick={() => _selectCard(card)} className='p-1 h-100'>
                        <div className='border shadow rounded p-0 pointer grow bg-white h-100'>

                            {card.layout === 0 && <>



                                {user?.hasProfilePic && <img className='img-fluid' src={`https://justthetap.s3.amazonaws.com/profile-pic/${card?.belongsTo}?${(new Date()).getTime()}`} alt='profilePic' />}
                                {!user?.hasProfilePic && <img className='img-fluid' src={require('../assets/profile.jpg')} alt='profilePic' />}

                                <div className={`py-1 bg-${card.color}`} />
                                <h3>{card.nickname}</h3>
                            </>}


                            {card.layout === 1 && <>

                                <div className={`halfish-${card.color} p-4`}>

                                    {user?.hasProfilePic && <img className='img-fluid img-rounded' style={{ borderRadius: '50%', borderWidth: 5, borderColor: '#efefef', borderStyle: 'solid' }} src={`https://justthetap.s3.amazonaws.com/profile-pic/${card?.belongsTo}`} alt='profilePic' />}
                                    {!user?.hasProfilePic && <img className='img-fluid img-rounded' style={{ borderRadius: '50%', borderWidth: 5, borderColor: '#efefef', borderStyle: 'solid' }} src={require('../assets/profile.jpg')} alt='profilePic' />}



                                </div>
                                <h3>{card.nickname}</h3>

                            </>}
                        </div>
                    </Col>


                ))}


            </Row>

            {cards?.length === 0 && <>
                <p className='lead text-center mt-2'>You cannot use this feature until you register a JustTheTap Card. Please visit our store to Order a JustTheTap Tapper or Virtual key.</p>
                <br />
                <a href='https://shop.justthetap.me' className='btn btn-primary'><AiFillShopping className='me-2' />Shop Our Store</a>

            </>}


            <Modal show={showNew} size='lg' static onHide={() => setShowNew(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <ClaimTapper close={() => setShowNew(false)} />
                </Modal.Body>

            </Modal>

        </Container>
    )
}