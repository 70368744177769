import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { AiFillShopping } from "react-icons/ai";
import { QRCode } from "react-qrcode-logo";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../_redux/actions";
import ReactGA from 'react-ga4'
export default function Background(props) {


    const canvasRef = useRef()
    const selectedCard = useSelector(state => state.selectedCard)
    const cards = useSelector(state => state.cards)
    const dispatch = useDispatch()

    const [num, setNum] = useState(1)
    const [set, setSet] = useState(false)
    useEffect(() => {

        if (process.env.REACT_APP_ENV === 'production') {
            ReactGA.send({ hitType: "pageview", page: "/background", title: "Background Viewed" });
        }
        if (!set) {

            setTimeout(() => {
                _setCanvasHeight(1, true)
                setSet(true)
            }, 300)
        }

        if (!selectedCard && cards?.length > 0) {
            dispatch(Actions.setSelectedCard(cards[0]))
        }

        document.title = 'JustTheTap - Backgrounds'

    }, [cards])
    const _setCanvasHeight = async (designNumber, initial) => {

        if (process.env.REACT_APP_ENV === 'production' && initial) {
            ReactGA.event({
                category: 'ButtonClick',
                action: 'backgroundPreview',
                label: 'Preview Background'
            });
        }

        setNum(designNumber)
        var canvas = document.getElementById('my-canvas');
        var heightRatio = 16 / 9;
        canvas.height = canvas.width * heightRatio;

        const canvasCtx = canvas?.getContext('2d')
        const image = new Image()
        image.src = `phone-bg/design-${designNumber}.jpg`
        await new Promise((resolve, reject) => {
            image.onload = () => {
                resolve()

                canvasCtx.drawImage(image, 0, 0, canvas.width, canvas.height)
            }
        })


        const qrCanvas = await html2canvas(document.getElementById('qr-code-mine'));

        const qr = qrCanvas.toDataURL("image/png", 1);



        const qrImg = new Image()

        qrImg.src = qr

        await new Promise((resolve, reject) => {
            qrImg.onload = () => {
                resolve()

            }
        })

        const c1 = scaleIt(qrImg, 0.3)


        // qrImg.width = canvas.width - 200
        // qrImg.height = canvas.width - 200

        const differenceHeight = (canvas.height - c1.height) / 2
        const differenceWidth = (canvas.width - c1.width) / 2

        await canvasCtx.drawImage(image, 0, 0, canvas.width, canvas.height)

        await canvasCtx.drawImage(c1, differenceWidth, differenceHeight, c1.width, c1.height)

    }

    function scaleIt(source, scaleFactor) {
        var c = document.createElement('canvas');
        var ctx = c.getContext('2d');
        var w = source.width * scaleFactor;
        var h = source.height * scaleFactor;
        c.width = w;
        c.height = h;
        ctx.drawImage(source, 0, 0, w, h);
        return (c);
    }

    const _downloadPhone = () => {
        var link = document.createElement('a');
        link.download = 'justthetap-bg.png';
        link.setAttribute('target', '__blank')
        link.href = `${process.env.REACT_APP_API}/bg/phone/${selectedCard._id}/${num}`
        document.body.append(link)
        link.click();
        link.remove()

        if (process.env.REACT_APP_ENV === 'production') {
            ReactGA.event({
                category: 'ButtonClick',
                action: 'downloadPhoneBG',
                label: 'Download Phone BG'
            });
        }

    }

    const _changeCard = (card) => {
        dispatch(Actions.setSelectedCard(card))
        _setCanvasHeight(1)
    }




    return (
        <Container fluid className='p-3 pb-5'>
            {cards?.length > 0 && <div className='p-3 rounded border bg-white shadow mb-5'>
                <h2 className='text-start '>Phone Background</h2>
                <p className='text-start mb-5'>Set this image as your lockscreen background to network easier!</p>
                <Row>
                    <Col md={3}>
                        <canvas ref={canvasRef} className="w-100" id='my-canvas'>

                        </canvas>
                        <Button className='mt-3' onClick={_downloadPhone}>Download High Resolution</Button>
                    </Col>
                    <Col className='p-2'>
                        <div className='p-2 rounded border bg-white shadow'>


                            <h3 >Change Background</h3>
                            <Row className='flex-wrap justify-content-center'>
                                <Col md={2} xs={3} >
                                    <img onClick={() => _setCanvasHeight(1)} className='img-fluid m-1 grow pointer border' src='/phone-bg/design-1.jpg' alt='design-1' />
                                </Col>
                                <Col md={2} xs={3}>
                                    <img onClick={() => _setCanvasHeight(2)} className='img-fluid m-1 grow pointer border' src='/phone-bg/design-2.jpg' alt='design-1' />
                                </Col>
                                <Col md={2} xs={3}>
                                    <img onClick={() => _setCanvasHeight(3)} className='img-fluid m-1 grow pointer border' src='/phone-bg/design-3.jpg' alt='design-1' />
                                </Col>
                                <Col md={2} xs={3}>
                                    <img onClick={() => _setCanvasHeight(4)} className='img-fluid m-1 grow pointer border' src='/phone-bg/design-4.jpg' alt='design-1' />
                                </Col>
                                <Col md={2} xs={3}>
                                    <img onClick={() => _setCanvasHeight(5)} className='img-fluid m-1 grow pointer border' src='/phone-bg/design-5.jpg' alt='design-1' />
                                </Col>
                                <Col md={2} xs={3}>
                                    <img onClick={() => _setCanvasHeight(6)} className='img-fluid m-1 grow pointer border' src='/phone-bg/design-6.jpg' alt='design-1' />
                                </Col>
                                <Col md={2} xs={3}>
                                    <img onClick={() => _setCanvasHeight(7)} className='img-fluid m-1 grow pointer border' src='/phone-bg/design-7.jpg' alt='design-1' />
                                </Col>
                                <Col md={2} xs={3}>
                                    <img onClick={() => _setCanvasHeight(8)} className='img-fluid m-1 grow pointer border' src='/phone-bg/design-8.jpg' alt='design-1' />
                                </Col>
                                <Col md={2} xs={3}>
                                    <img onClick={() => _setCanvasHeight(9)} className='img-fluid m-1 grow pointer border' src='/phone-bg/design-9.jpg' alt='design-1' />
                                </Col>
                                <Col md={2} xs={3}>
                                    <img onClick={() => _setCanvasHeight(10)} className='img-fluid m-1 grow pointer border' src='/phone-bg/design-10.jpg' alt='design-1' />
                                </Col>
                                <Col md={2} xs={3}>
                                    <img onClick={() => _setCanvasHeight(11)} className='img-fluid m-1 grow pointer border' src='/phone-bg/design-11.jpg' alt='design-1' />
                                </Col>

                                <Col md={2} xs={3}>
                                    <img onClick={() => _setCanvasHeight(12)} className='img-fluid m-1 grow pointer border' src='/phone-bg/design-12.jpg' alt='design-1' />
                                </Col>



                            </Row>
                        </div>
                    </Col>
                    <Col>
                        <div className='p-2 rounded border bg-white shadow'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='weight-bold'>Select Card</Form.Label>
                                <Form.Select value={selectedCard?._id} required onChange={e => _changeCard(e.target.value)} >
                                    {cards?.map(card => (
                                        <option value={card?._id}>{card.nickname}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <QRCode
                                value={`${process.env.REACT_APP_URL}/hi/${selectedCard?.nanoid}`}
                                logoImage="/logo.png"
                                logoWidth={40}
                                id='qr-code-mine'
                                size={200}
                                logoHeight={40}
                                qrStyle='dots'
                                fgColor='#3a86ff'
                            />
                        </div>
                    </Col>
                </Row>
            </div>}
            {cards?.length === 0 && <>
                <h2 className='text-start '>Phone Background</h2>
                <p className='lead text-center'>You cannot use this feature until you register a JustTheTap Card. Please visit our store to Order a JustTheTap Tapper or Virtual key.</p>
                <br />
                <a href='https://shop.justthetap.me' className='btn btn-primary'><AiFillShopping className='me-2' />Shop Our Store</a>

            </>}
        </Container>
    )
}