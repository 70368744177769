import { Col, Row, Button } from "react-bootstrap";

export default function StickyTop({ color, userId, cardId, isPreview, log }) {

   
    return (

        <Row>
            
            {isPreview && <Col>
                {isPreview && <p className='lead'><strong className={`text-${color}`}>IMPORTANT!</strong> This is a preview link. Do <strong className={`text-${color}`}>NOT</strong> Share This Link, analytics will not work.</p>}
            </Col>}
            <Col>
                <p className={`text-end mt-0 m-2`}>Powered By <a href='https://shop.justthetap.me' target={"__blank"}>JustTheTap                           <img width='50rem' height='50rem' className='ms-2 img-rounded' src={require('../assets/logo-trans.png')} alt='jtt logo' /></a>
                </p>
            </Col>
        </Row>




    )
}