import { Container, Row, Col, Form, Button, Spinner, Alert, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserAlt } from 'react-icons/fa'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import API from "../../_helpers/api";
import { useDispatch } from "react-redux";
import Actions from '../../_redux/actions'
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

export default function Name(props) {

    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [prefix, setPrefix] = useState('')
    const [suffix, setSuffix] = useState('')
    const [pronouns, setPronouns] = useState('')


    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch()


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

    }, [])

    const _updateUser = async (e) => {
        e.preventDefault()
        setLoading(true)
        const res = await API.updateUser({
            fName,
            lName,
            prefix,
            suffix,
            pronouns
        })
        setLoading(false)

        dispatch(Actions.setUser(res.user))
        dispatch(Actions.nextSignup())

    }


    return (
        <Container className='p-md-4 pt-0 text-center'>


            <Row className='border shadow-lg rounded mx-auto animate__animated animate__fadeInRight '>
                <Col md={6} className='bg-primary rounded p-md-3 text-white' style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                    <div className='d-flex flex-column align-items-center justify-content-center h-100 p-3'>
                        <h2 style={{ fontSize: '6rem' }} className='wave'>👋</h2>
                        <h1 className='text-white'>Hello! Let's Get to Know You Better!</h1>
                        <p><i>This information will appear on your card and can always be changed later.</i></p>
                    </div>
                </Col>

                <Col md={6} className='mx-auto p-xs-3 p-md-5 '>
                    <Form className='mt-4' onSubmit={_updateUser}>
                        <Form.Group className="mb-3 text-start">
                            <Form.Label className='text-start'>First Name</Form.Label>
                            <Form.Control required type="text" value={fName} onChange={e => setFName(e.target.value)} placeholder="First Name" />
                        </Form.Group>
                        <Form.Group className="mb-3 text-start">
                            <Form.Label className='text-start'>Last Name</Form.Label>
                            <Form.Control required type="text" value={lName} onChange={e => setLName(e.target.value)} placeholder="Last Name" />
                        </Form.Group>
                        <Form.Group className="mb-3 text-start">
                            <Form.Label className='text-start'>Prefix <i>(Optional)</i></Form.Label>
                            <Form.Control type="text" value={prefix} onChange={e => setPrefix(e.target.value)} placeholder="Sir. Dr." />
                        </Form.Group>

                        <Form.Group className="mb-3 text-start">
                            <Form.Label className='text-start'>Suffix <i>(Optional)</i></Form.Label>
                            <Form.Control type="text" value={suffix} onChange={e => setSuffix(e.target.value)} placeholder="JR. III." />
                        </Form.Group>


                        <Form.Group className="mb-3 text-start">
                            <Form.Label className='text-start'>Pronouns <i>(Optional)</i></Form.Label>
                            <Form.Control type="text" value={pronouns} onChange={e => setPronouns(e.target.value)} placeholder="Pronouns" />
                        </Form.Group>



                        <Button type='submit' variant='primary px-5 m-3' disabled={loading}>Next</Button>
                        {loading && <div className='text-center mt-2'>
                            <Spinner animation="border" />
                        </div>}

                        {err !== '' && <Alert variant='danger p-2 mt-2'>Error: {err}</Alert>}

                    </Form>
                </Col>
            </Row>


        </Container >
    )
}