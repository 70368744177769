import { useEffect } from "react";
import { Container, Row, Col, Form, Button, Spinner, Alert, ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";

import Logo from '../assets/logo-trans.png'
import ClaimTag from "../_components/signup/ClaimTag";
import Complete from "../_components/signup/Complete";
import HaveCard from "../_components/signup/HaveCard";
import Name from "../_components/signup/Name";
import PersonalInfo from "../_components/signup/PersonalInfo";
import Photo from "../_components/signup/Photo";




export default function Signup(props) {

    const signupStep = useSelector(state => state.signupStep)

    useEffect(() => {
        //document.title = 'ScamWall | Signup'
        window.addEventListener("beforeunload", _beforeUnload);
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', _popsState);

        return () => {
            window.removeEventListener('popstate', _popsState)
            window.removeEventListener('beforeunload', _beforeUnload)

        }
    }, [])

    const _popsState = (ev) => {
        window.history.pushState(null, document.title, window.location.href);

    }
    const _beforeUnload = (ev) => {
        ev.preventDefault();
        return ev.returnValue = 'Closing will remove your progress';
    }



    return (
        <Container fluid className='p-0 pb-5 ' >
            <div className='sticky-top'>
                <Row className='bg-white p-2 justify-content-center '>
                    <Col md={3} />
                    <Col md={6} className='my-auto me-auto text-center'>
                        <Row className='justify-content-center'>
                            <Col sm={1} xs={2} >
                                <img src={Logo} className='img-fluid ' alt='Logo' />
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <ProgressBar now={Math.floor((signupStep / 5) * 100) || 0} className='m-0 p-0 mb-3 shadow' style={{ borderRadius: 0 }} />
            </div>

            {signupStep === 0 && <PersonalInfo />}
            {signupStep === 1 && <Name />}
            {signupStep === 2 && <Photo />}

            {signupStep === 3 && <HaveCard />}

            {signupStep === 4 && <ClaimTag />}


            {signupStep === 5 && <Complete />}


        </Container>
    )
}