import ActionTypes from "./action.types";
const Actions = {
    setUser: (user) => ({
        type: ActionTypes.SET_USER,
        payload: {
            user
        }
    }),


    setToast: (header, msg) => ({
        type: ActionTypes.SET_TOAST,
        payload: {
            header,
            msg
        }
    }),

    setSelectedCard: (card) => ({
        type: ActionTypes.SET_SELECTED_CARD,
        payload: {
            card
        }
    }),

    setCards: (cards) => ({
        type: ActionTypes.SET_CARDS,
        payload: {
            cards
        }
    }),

    nextSignup: () => ({
        type: ActionTypes.NEXT_SIGNUP,

    }),


    clearToast: () => ({
        type: ActionTypes.CLEAR_TOAST
    })


}


export default Actions;