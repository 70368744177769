import { Container, Row, Col, Form, Button, Spinner, Alert, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBoxOpen } from 'react-icons/fa'
import API from "../../_helpers/api";
import { useDispatch } from "react-redux";
import Actions from "../../_redux/actions";

export default function HaveCard(props) {


    const navigate = useNavigate()
    const dispatch = useDispatch()


    useEffect(() => {

    }, [])


    return (
        <Container className='p-4 pt-0 text-center vh-100 '>

            <Row className='border shadow rounded mx-auto animate__animated animate__fadeInRight  my-auto p-5'>
                <Col md={5} className='mx-auto'>
                    <FaBoxOpen className='text-primary' size='6rem' />
                    <h1>Do You Have JustTheTap Product Keys?</h1>
                    <Button className='my-4' variant='primary' onClick={() => dispatch(Actions.nextSignup())}>Yes, I Have My JustTheTap ID and Password </Button>
                    <br className=' mt-5' />
                    <a className='noDecoration mt-2' href='https://shop.justthetap.me/product/justthetap-tapper/' target="__blank">No, I need to buy a JustTheTap Tapper</a>
                    <p><small><em>Your keys will be instantly emailed to you at checkout. Come back here when done!</em></small></p>
                </Col>
            </Row>
        </Container>
    )
}