import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

export default function WaveEffect(props) {

    const [hide, setHide] = useState(false)
    const [out, setOut] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setHide(true)
        }, 2000)
        setTimeout(() => {
            setOut(true)
        }, 3000)
    }, [])
    return (
        <>

            {!out ? < Row className={`position-absolute vh-100  w-100 animate__animated ${hide ? 'animate__fadeOut' : 'animate__fadeIn'}`}>
                <Col className="d-flex flex-column justify-content-center align-items-center text-center w-100"
                >
                    <h2 style={{ fontSize: '6rem' }} className='wave'>👋</h2>

                </Col>
            </Row> : <></>}
        </>

    )
}