import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'

import Reducer from './reducer'
var store
if (process.env.NODE_ENV === 'production') {
    store = createStore(Reducer)
} else {
    store = createStore(Reducer, applyMiddleware(logger))

}
//const store = createStore(Reducer)
export default store