import { Container, Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react'
import { FaDog } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import API from '../../_helpers/api'
import Actions from '../../_redux/actions'
import { BsPersonCircle } from 'react-icons/bs'
import { canvasPreview } from '../../_helpers/canvasPreview'
import { useDebounceEffect } from '../../_helpers/useDebounceEffect'

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export default function Photo(props) {

    const [imgSrc, setImgSrc] = useState('')
    const user = useSelector(state => state.user)
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(1)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const [show, setShow] = useState(false)
    const canvasRef = useRef()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }




    function centerAspectCrop(
        mediaWidth,
        mediaHeight,
        aspect
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 400,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    const _upload = async () => {
        setLoading(true)
        const base64Image = previewCanvasRef.current.toDataURL('image/jpeg');

        const res = await fetch(base64Image)
        const data = await API.presignProfile()

        await API.uploadImage(data.url, await res.blob())
    }


    function dataURItoBlob(dataURI) {
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {
            type: mimeString
        });
    }



    const _submit = async (e) => {
        e.preventDefault()
        _upload()
        const tempUser = JSON.parse(JSON.stringify(user))
        tempUser.hasProfilePic = true
        const res = await API.updateUser(tempUser)
        dispatch(Actions.setUser(res.user))

        setLoading(false)
        dispatch(Actions.nextSignup())

    }
    return (

        <Container className='p-4 pt-0 text-center vh-100'>
            <Row >

                <Col md={6} className='mx-auto border shadow-lg rounded  p-4 mt-4 animate__animated animate__fadeIn '>
                    <Row>
                        <Col md={4} className='mx-auto'>
                            {imgSrc === '' && <BsPersonCircle size='6rem' className='text-primary' />}
                            {imgSrc !== '' && (

                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={aspect}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        className='img-fluid'
                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>

                            )}


                        </Col>
                    </Row>
                    <h2>Upload Your Headshot</h2>
                    <p>This image will be shown when you share your contact card.</p>
                    <p>NOTE: You can crop this image</p>


                    <Row>
                        <Col md={7} className='mx-auto'>
                            <Form onSubmit={_submit}>
                                <Form.Group className="mb-3 text-start">
                                    <Form.Control placeholder='Pet Image' size='lg' required type="file" onChange={onSelectFile} />
                                </Form.Group>
                                < hr />
                                {loading && <div className='text-center my-2'><Spinner animation='border' /></div>}
                                <Button variant='primary w-100' type='submit' disabled={loading || imgSrc === ''}>Upload Image</Button>
                                <br />
                                <Button variant='link no-decoration mt-3' onClick={() => setShow(true)}>
                                    Skip for Now
                                </Button>

                                <Row style={{ display: 'none' }}>
                                    <Col md={6} className='mx-auto'>
                                        {(completedCrop) && (
                                            <canvas
                                                ref={previewCanvasRef}
                                                className='img-fluid'

                                                style={{
                                                    objectFit: 'contain',
                                                    width: completedCrop.width,
                                                    height: completedCrop.height,
                                                }}
                                            />)}
                                    </Col>

                                </Row>
                            </Form>
                        </Col>
                    </Row>



                </Col>

            </Row>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Are You Sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>You can always upload a headshot later but don't forget!</Modal.Body>
                <Modal.Footer>
                    <Button variant="link noDecoration" onClick={() => dispatch(Actions.nextSignup())}>
                        Skip for Now
                    </Button>
                    <Button variant="primary" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}