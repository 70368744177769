import ActionTypes from './action.types'

const initialState = {
    signupStep: 0,
    showToast: false
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_USER:
            return {
                ...state,
                user: action.payload.user
            }

        case ActionTypes.NEXT_SIGNUP:
            return {
                ...state,
                signupStep: state.signupStep + 1
            }

        case ActionTypes.CLEAR_TOAST:
            return {
                ...state,
                showToast: false
            }

        case ActionTypes.SET_SELECTED_CARD:
            return {
                ...state,
                selectedCard: action.payload.card
            }

        case ActionTypes.SET_CARDS:
            return {
                ...state,
                cards: action.payload.cards
            }



        case ActionTypes.SET_TOAST:
            return {
                ...state,
                showToast: true,
                toastHeader: action.payload.header,
                toastMsg: action.payload.msg
            }
        default:
            return state
    }
}

export default Reducer