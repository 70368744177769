import { Col, Row, Button } from "react-bootstrap";
import {MdPermContactCalendar} from 'react-icons/md'
export default function StickyBottom({ color, user, cardId, log }) {

    const _log = async () => {
        log('CONTACT_ADDED')
    }
    return (
        <Row className='fixed-bottom'>
            <Col md={6} className={`mx-auto see-through  py-2`}>
                <a onClick={_log} href={`${process.env.REACT_APP_API}/card/vcf/${user?._id}/${cardId}`} download  className={`btn btn-${color} text-white`} rel="noreferrer"> <MdPermContactCalendar className='my-auto me-3' />Add to Contacts</a>
             
            </Col>
        </Row>
    )
}