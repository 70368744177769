import { Row, Col } from "react-bootstrap";
import { BsFillEnvelopeFill, BsFillCalendarFill, BsFacebook, BsFillFileEarmarkTextFill, BsGithub, BsLinkedin, BsFillTelephoneFill, BsTwitter, BsYoutube, BsGlobe } from 'react-icons/bs'
import { MdLocationCity } from 'react-icons/md'
import { AiFillInstagram, AiOutlineLink } from 'react-icons/ai'
import { FaStickyNote, FaTiktok } from 'react-icons/fa'
import { useState } from "react";

export default function ShownField({ field, color }) {

    const [showBg, setShowBg] = useState(false)




    let _formatPhoneNumber = (str) => {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            //Remove the matched extension code
            //Change this to format for any country code.
            let intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }

        return null;
    }

    return (
        <Row className={`my-3 hoverable ${showBg && `bg-${color}`}`} onMouseEnter={() => setShowBg(true)} onMouseLeave={() => setShowBg(false)}>
            <Col xs={2}>
                <div className={`bg-${color} cardBtn d-flex  flex-column justify-content-center align-items-center`}>
                    {field.type === 'ADDRESS' && <MdLocationCity className="text-white" size='1.5rem' />}
                    {field.type === 'CALENDLY' && <BsFillCalendarFill className="text-white" size='1.5rem' />}
                    {field.type === 'EMAIL' && <BsFillEnvelopeFill className="text-white" size='1.5rem' />}
                    {field.type === 'FACEBOOK' && <BsFacebook className="text-white" size='1.5rem' />}
                    {field.type === 'FILE' && <BsFillFileEarmarkTextFill className="text-white" size='1.5rem' />}
                    {field.type === 'GITHUB' && <BsGithub className="text-white" size='1.5rem' />}
                    {field.type === 'INSTAGRAM' && <AiFillInstagram className="text-white" size='1.5rem' />}
                    {field.type === 'LINK' && <AiOutlineLink className="text-white" size='1.5rem' />}
                    {field.type === 'LINKED_IN' && <BsLinkedin className="text-white" size='1.5rem' />}
                    {field.type === 'NOTE' && <FaStickyNote className="text-white" size='1.5rem' />}
                    {field.type === 'PHONE' && <BsFillTelephoneFill className="text-white" size='1.5rem' />}
                    {field.type === 'TIKTOK' && <FaTiktok className="text-white" size='1.5rem' />}
                    {field.type === 'TWITTER' && <BsTwitter className="text-white" size='1.5rem' />}
                    {field.type === 'YOUTUBE' && <BsYoutube className="text-white" size='1.5rem' />}
                    {field.type === 'WEBSITE' && <BsGlobe className="text-white" size='1.5rem' />}

                </div>
            </Col>
            <Col className="my-auto">
                {["CALENDLY", "FACEBOOK", "GITHUB", "INSTAGRAM", "LINK", "LINKED_IN", "TIKTOK", "TWITTER", "YOUTUBE", "WEBSITE"].includes(field.type) && <>
                    <div className="text-start">
                        <a href={field.text} className='text-black noDecoration text-start my-link' target="__blank">{field.displayText}</a>

                    </div>
                </>}

                {field.type === 'PHONE' && <>

                    <div className="text-start">
                        <a href={`tel:` + field.text} className='text-black noDecoration text-start my-link'>{_formatPhoneNumber(field?.displayText)}</a>

                    </div>

                </>}

                {field.type === 'FILE' && <>

                    <div className="text-start">
                        <a href={`https://justthetap.s3.amazonaws.com/files/${field?.text}`} target="__blank" className='text-black noDecoration text-start my-link'>{field?.displayText}</a>
                    </div>

                </>}

                {field.type === 'EMAIL' && <>

                    <div className="text-start">
                        <a href={'mailto:' + field.text} className='text-black noDecoration text-start my-link' target="__blank">{field.displayText}</a>
                    </div>

                </>}

                {['ADDRESS', 'NOTE'].includes(field.type) && <>

                    <p className="my-auto text-start">
                        {field?.displayText}
                    </p>

                </>}

                {/* <p className="my-auto text-start">
                    {field?.displayText}
                </p> */}



            </Col>
        </Row>
    )
}