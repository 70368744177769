import { useState } from "react";
import { Alert, Button, Container, Form, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import API from "../_helpers/api";
import Actions from "../_redux/actions";

export default function Replace({cardID, close}) {

    const [msg, setMsg] = useState('')
    const [addy, setAddy] = useState('')
    const [loading ,setLoading] = useState(false)
    const [err , setErr] = useState(false)


    const dispatch = useDispatch()
    const _replace = async (e) => {
        e.preventDefault()
        setLoading(true)
        setErr(false)

        const res = await API.replaceCard(cardID, addy, msg)
        if(res.isErr){
            setErr(true)
            setLoading(false)
            return
        }

        close()
        dispatch(Actions.setToast(`✅ Your Request Has Been Received`, 'You will hear back from us within 72 hours'))
        

    }
    return (
        <Container>
            <Form onSubmit={_replace}>
                <Form.Group className="mb-3 text-start">
                    <Form.Label className='text-start weight-bold'>Reason For Replacement</Form.Label>
                    <Form.Control as='textarea' rows={4} required type="text" value={msg} onChange={e => setMsg(e.target.value)} placeholder="Let us know what is going on" />
                </Form.Group>

                <Form.Group className="mb-3 text-start">
                    <Form.Label className='text-start weight-bold'>Address to Send Replacement</Form.Label>
                    <Form.Control as='textarea' rows={4} required type="text" value={addy} onChange={e => setAddy(e.target.value)} placeholder="123 Home St" />
                </Form.Group>

                <div className='text-center'>
                    <Button type='submit'>Submit Replacement Request {loading && <Spinner animation='border' size='sm'/>}</Button>
                </div>

                {err && <Alert variant='danger text-center p-1 m-2'>This Card already has a Replacement Request</Alert>}
            </Form>
        </Container>
    )
}