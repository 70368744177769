import { Button, Container, Spinner, Row, Col, Alert, Form, InputGroup } from "react-bootstrap"
import { useEffect, useState } from 'react'
import API from "../_helpers/api"
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Actions from '../_redux/actions'
export default function Reset(props) {

    const [loading, setLoading] = useState(true)
    const [err, setErr] = useState(false)
    const [isInvite, setIsInvite] = useState(false)
    const [orgName, setOrgName] = useState('')
    const [pwd, setPwd] = useState('')
    const [confirmPwd, setConfirmPwd] = useState('')

    const [submitLoading, setSubmitLoading] = useState(false)
    const [pwErr, setPwErr] = useState('')


    const [showPw, setShowPw] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)

    const searchParams = new URLSearchParams(window.location.search)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        _checkToken()

    }, [])

    const _checkToken = async () => {
        const res = await API.checkResetToken(searchParams.get('token'))
        console.log(res)
        if (res.errMsg) {
            setErr(true)
        } else {
            setIsInvite(res.isInvite)
            setOrgName(res.orgName)
        }

        setLoading(false)
    }

    const _reset = async (e) => {
        e.preventDefault()
        setPwErr('')
        setSubmitLoading(true)
        if (pwd !== confirmPwd) {
            setPwErr('Passwords Did Not Match')
            setSubmitLoading(false)
        } else if (!/(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/.test(pwd)) {
            setPwErr('Passwords must be at least 8 characters long, contain 1 uppercase character, 1 lowercase character, 1 number, and 1 special character')
            setSubmitLoading(false)
        }else{
            const res = await API.resetPassword(searchParams.get('token'), pwd)
            if(res.errMsg){
                setPwErr(res.errMsg)
            }else{
                    dispatch(Actions.setToast('New Password Set ✅', 'Please Login Using Your New Password' ))
                    navigate('/login')
            }
        }
        setSubmitLoading(false)


    }

    return (
        <Container className='p-4'>


            <Row>
                <Col md={6} className='bg-white shadow border mx-auto rounded p-3'>
                    <Row>
                        <Col xs={4} className='mx-auto'>
                            <img alt='Asyncrio Logo' className='img-fluid' src={require('../assets/logo.png')} />

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={8} className='mx-auto'>
                            {!loading && !err && <>
                                <Form onSubmit={_reset} className='text-start '>
                                    <Form.Group className="mb-3 text-start" >
                                        <Form.Label className='text-start'>Password</Form.Label>

                                        <InputGroup className="mb-3">
                                            <Form.Control required type={!showPw ? "password" : 'text'} placeholder="Password" value={pwd} onChange={e => setPwd(e.target.value)} />
                                            <InputGroup.Text>{showPw ? <AiFillEye className='pointer' onClick={() => setShowPw(!showPw)} /> : <AiFillEyeInvisible className='pointer' onClick={() => setShowPw(!showPw)} />}</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3 text-start" >
                                        <Form.Label className='text-start'>Confirm Password</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control required type={!showConfirm ? "password" : 'text'} placeholder="Password" value={confirmPwd} onChange={e => setConfirmPwd(e.target.value)} />
                                            <InputGroup.Text>{showConfirm ? <AiFillEye className='pointer' onClick={() => setShowConfirm(!showConfirm)} /> : <AiFillEyeInvisible className='pointer' onClick={() => setShowConfirm(!showConfirm)} />}</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>

                                    <div className='text-center mt-3'>
                                        <Button type='submit' disabled={submitLoading || pwd === '' || confirmPwd === ''}>Submit</Button>
                                    </div>

                                    {submitLoading && <div className='text-center mt-3'><Spinner animation='grow' /></div>}


                                    {pwErr !== '' && <Alert variant='danger text-center p-2 mt-2'>{pwErr}</Alert>}

                                </Form>
                            </>}
                        </Col>
                    </Row>
                    {!loading && err && <Alert variant='danger text-center p-2'>This Link is Invalid.</Alert>}
                    {loading && <div className='text-center'><Spinner animation='grow' /></div>}

                    {isInvite && <p className='text-center mt-3'>Invited to Join <strong className='text-primary'>{orgName}</strong></p>}
                </Col>
            </Row >


        </Container >
    )
}