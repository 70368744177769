import { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeftCircle, BsPencil, BsCloudDownload, BsGear, BsFillEnvelopeFill, BsFillTelephoneFill } from 'react-icons/bs'
import { AiFillTwitterCircle, AiFillLinkedin, AiFillFacebook } from 'react-icons/ai'
import { MdOutlineLocalShipping } from 'react-icons/md'
import { FaCopy } from 'react-icons/fa'
import { QRCode } from 'react-qrcode-logo';
import Chart from "react-apexcharts";
import html2canvas from "html2canvas";

import Actions from "../_redux/actions";
import ShownField from "../_components/ShownField";
import API from "../_helpers/api";
import StickyBottom from "../_components/StickyBottom";
import StickyTop from "../_components/StickyTop";
import WaveEffect from "../_components/Wave";




//TODO: ADD PERSONALIZED LINK AS PRO OPTION
// ALSO CHECK IF NEW CARD, CLAIM INSTEAD OF SHOWING
export default function ViewCard(props) {
    const { cardId } = useParams()

    const [card, setCard] = useState()
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(true)
    const [err, setErr] = useState(true)

    const [isPreview, setIsPreview] = useState(false)

    const params = new URLSearchParams(window.location.search) // id=123

    const loggedInUser = useSelector(state => state.user)
    const dispatch = useDispatch()
    const _getCard = async () => {
        const res = await API.getCard(cardId)


        setCard(res.card)
        setUser(res.user)
        if (!res.card) {
            setErr(true)
        }
        setLoading(false)

        const type = params.get('t')
        if (type === 'preview') {
            setIsPreview(true)
        }

        if (!localStorage.getItem('viewList')) {
            localStorage.setItem('viewList', '[]')
        }


        const viewedList = JSON.parse(localStorage.getItem('viewList'))
        if (type !== 'preview') {
            //log view
            if (!viewedList.includes(cardId)) {
                API.logAnalytic(res.card._id, res.user._id, 'UNIQUE')
                viewedList.push(cardId)
            }

            //NO TYPE, It's NFC
            if (type === 'qr') {
                API.logAnalytic(res.card._id, res.user?._id, 'QR')
            } else if (type === 'contact') {
                API.logAnalytic(res.card._id, res.user?._id, 'CONTACT_VIEW')
            } else if (type === 'link') {
                API.logAnalytic(res.card._id, res.user?._id, 'LINK_VIEW')
            } else {
                API.logAnalytic(res.card._id, res.user?._id, 'NFC')
            }
        }

        localStorage.setItem('viewList', JSON.stringify(viewedList))

        if (loggedInUser && (res.card.belongsTo !== loggedInUser?._id)) {
            const userRes = await API.addContact(res.card._id)
            dispatch(Actions.setUser(userRes.user))
        }
    }


    const _log = async (type, fieldId) => {
        if (!isPreview) {
            API.logAnalytic(card._id, user?._id, type, fieldId)

        }

    }


    useEffect(() => {
        _getCard()
    }, [])


    return (
        <Container fluid className='p-2 pb-5'>
            <WaveEffect />
            <StickyTop isPreview={isPreview} color={card?.color} userId={user?._id} cardId={card?._id} />

            <Row>
                <Col xs={12} md={4} className='mx-auto'>


                    <div className='border shadow rounded p-0 bg-white w-100 mb-5'>
                        {card?.layout === 0 && <>

                            {user?.hasProfilePic && <img className='img-fluid' src={`https://justthetap.s3.amazonaws.com/profile-pic/${card?.belongsTo}?${(new Date()).getTime()}`} alt='profilePic' />}
                            {!user?.hasProfilePic && <img className='img-fluid' src={require('../assets/profile.jpg')} alt='profilePic' />}

                            <div className={`py-1 bg-${card?.color}`} />
                        </>}


                        {card?.layout === 1 && <>

                            <div className={`halfish-${card?.color} p-4`}>

                                {user?.hasProfilePic && <img className='img-fluid img-rounded' style={{ borderRadius: '50%', borderWidth: 5, borderColor: '#efefef', borderStyle: 'solid' }} src={`https://justthetap.s3.amazonaws.com/profile-pic/${card?.belongsTo}`} alt='profilePic' />}
                                {!user?.hasProfilePic && <img className='img-fluid img-rounded' style={{ borderRadius: '50%', borderWidth: 5, borderColor: '#efefef', borderStyle: 'solid' }} src={require('../assets/profile.jpg')} alt='profilePic' />}



                            </div>

                        </>}
                        <h2>{user?.prefix} {user?.fName} {user?.lName} {user?.suffix}</h2>
                        {card?.includePronouns && <p>{user?.pronouns}</p>}
                        <hr className='mx-5' />
                        {card?.title !== '' && <h5 className="weight-bold">{card?.title}</h5>}
                        {card?.department !== '' && <h5 className={`weight-bold text-${card?.color}`} >{card?.department}</h5>}
                        {card?.company !== '' && <h5 ><em>{card?.company}</em></h5>}

                        <div className='p-3'>
                            {card?.fields?.sort((a, b) => a.order - b.order)?.map(field => (
                                <ShownField onClick={_log} cardId={cardId} userId={user?._id} field={field} color={card?.color} />
                            ))}
                        </div>
                    </div>

                </Col>
            </Row>
            <StickyBottom color={card?.color} user={user} cardId={card?._id} log={_log} />
        </Container >
    )
}